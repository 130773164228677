import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import Container from '../container'
import Caption from '../caption'
import { chunkArray } from '../../utils'

export const PrismicImageCards = ({ cards }) => {
  const { width } = useWindowDimensions()

  const imgUrl = (img) => {
    if (width <= 320) {
      return img?.thumbnails?.Mobile?.gatsbyImageData
    } else if (width <= 1024) {
      return img?.thumbnails?.Tablet?.gatsbyImageData
    } else {
      return img?.gatsbyImageData
    }
  }

  return (
    <div className="py-5 xs:my-0 text-black-900">
      <Container>
        <div className="block px-4 xs:grid w-full items-center grid-rows-1 grid-cols-12 gap-x-2 md:gap-x-5">
          {chunkArray(cards, 2).map((section, index) => (
            <div
              className="xs:py-10 col-start-1 col-span-12 md:col-start-2 md:col-span-10"
              key={index}
            >
              <div className="flex flex-col xs:flex-row justify-start font-serif text-black-900 xs:gap-x-4 sm:gap-x-12">
                {section.map((item, i) => (
                  <div className="my-5 xs:my-0 xs:w-1/2" key={i}>
                    <div className="items-center">
                      <GatsbyImage
                        image={imgUrl(item?.image)}
                        className="w-full object-cover object-center"
                        alt={item?.image?.alt || 'eminent project'}
                      />
                      {item?.caption && <Caption>{item.caption}</Caption>}
                    </div>
                    <div className="mt-2 flex items-center uppercase text-lg sm:text-x22 md:text-x26 font-sans text-black-900 leading-tight">
                      {item?.title?.text}
                    </div>
                    <div
                      className="text-base leading-relaxed md:text-base font-serif text-black-900 mt-4"
                      dangerouslySetInnerHTML={{
                        __html: item?.description?.html,
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </Container>
    </div>
  )
}