import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import { Layout } from '../components/Layout'
import MemberSlices from '../components/slices/member'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { repositoryConfigs } from '../utils/prismicPreviews'

const MemberTemplate = ({
  data: {
    prismicMember: {
      data: { body: slices },
    },
  },
}) => {
  return (
    <Layout>
      <MemberSlices slices={slices} />
    </Layout>
  )
}

export const Head = ({ data, location }) => {
  const {
    prismicMember: {
      data: { seo_title, seo_keywords, seo_image, seo_description },
    },
  } = data
  const metadata = {
    title: seo_title,
    description: seo_description,
    pathname: location.pathname,
    ogTagImage: seo_image?.url,
    keywords: seo_keywords,
  }
  return <SEO metadata={metadata} />
}

export default withPrismicPreview(MemberTemplate, repositoryConfigs)

export const MemberTemplateQuery = graphql`
  query MemberTemplate($uid: String!) {
    prismicMember(uid: { eq: $uid }) {
      id
      uid
      type
      data {
        seo_title
        seo_keywords
        seo_image {
          url
        }
        seo_description
        body {
          ...SliceMember
          ...SliceMemberCarousel
        }
      }
    }
  }
`
