import React from 'react'

import { ServiceDesignHero } from '../sections/service-design-hero'
import { ServiceDesignServices } from '../sections/service-design-services'
import { ContactUs } from '../sections/contactus'
import { GallerySection } from '../sections/gallery'
import { ServiceDesignTextbox } from '../sections/service-design-textbox'
import { ServiceDesignCarouselSmallCard } from '../sections/service-design-carousel-smallcard'
import { ServiceDesignTwoColumn } from '../sections/service-design-two-column'

const ServiceDesignSlices = ({ classNames = {}, slices }) => {
  return slices.map((slice) => {
    const key = slice.id

    switch (slice.slice_type) {
      case 'service_hero':
        return <ServiceDesignHero slice={slice} key={key} />
      case 'design_services':
        return <ServiceDesignServices slice={slice} key={key} />
      case 'contact_us':
        return <ContactUs slice={slice} key={key} />
      case 'gallery':
        return <GallerySection slice={slice} key={key} />
      case 'text_box':
        return <ServiceDesignTextbox slice={slice} key={key} />
      case 'carousel-smallcard':
        return <ServiceDesignCarouselSmallCard slice={slice} key={key} />
      case 'design_services_two_column':
        return <ServiceDesignTwoColumn slice={slice} key={key} />
      default:
        return null
    }
  })
}

export default ServiceDesignSlices
