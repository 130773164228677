import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import Container from '../container'
import { PrismicCallout } from '../prismicCallout'
import Caption from '../caption'

export const PrismicTwoColumn = ({ items, width = 'Middle' }) => {
  return (
    <div className="">
      {items.map((item, index) => {
        return (
          <div className="py-10 mx-4" key={index}>
            <Container>
              <div className="lg:grid grid-cols-12 gap-x-5">
                <div
                  className={`col-start-1 col-span-12 ${
                    width === 'Middle' && 'lg:col-start-2 lg:col-span-10'
                  } flex flex-col-reverse items-start ${
                    index % 2 === 0 ? 'sm:flex-row' : 'sm:flex-row-reverse'
                  } gap-x-12`}
                >
                  <div className="flex-1 justify-start flex flex-col mt-4 sm:mt-0">
                    <h3 className="text-x26 font-sans uppercase mb-3 text-black-900">
                      {item?.headline}
                    </h3>
                    {item?.callout && (
                      <div className="flex items-center mb-6 sm:mb-8 md:mb-10">
                        <PrismicCallout
                          callout={item?.callout}
                          position="Left Justify"
                        />
                      </div>
                    )}
                    <div
                      className="leading-relaxed font-serif text-base text-black-900"
                      dangerouslySetInnerHTML={{
                        __html: item?.content?.html,
                      }}
                    />
                  </div>
                  <div className="flex-1 flex flex-col justify-start w-full">
                    <GatsbyImage
                      image={item?.image.gatsbyImageData}
                      className="leadership-photo w-full"
                      alt="about"
                      width="456"
                      height="570"
                    />
                    {item.caption && <Caption>{item.caption}</Caption>}
                  </div>
                </div>
              </div>
            </Container>
          </div>
        )
      })}
    </div>
  )
}
