import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import Container from '../../container'
import Link from '../../link'
import BackIcon from '../../svgIcons/backIcon'
import DateIcon from '../../svgIcons/dateIcon'
import TimeIcon from '../../svgIcons/timeIcon'

export const BlogPostHero = ({ slice }) => {
  const { primary: primaryData, items: itemsData } = useMemo(
    () => slice,
    [slice],
  )

  return (
    <>
      <div className="mx-4 blog-post-hero text-black-900">
        <Link
          to="/blog"
          className="back-button text-black-900 text-base md:text-base font-serif flex items-center mb-10"
        >
          <BackIcon className="mr-2 md:mr-4" />
          Back to Blog
        </Link>
        <Container>
          <div className="mx-0 md:mx-3 mb-4 sm:mb-5 md:mb-6 flex flex-col justify-start font-serif uppercase text-center text-gold-900 text-base md:text-base">
            {primaryData?.category} tips
          </div>
          <div className="mx-0 md:mx-3 mb-4 sm:mb-5 md:mb-6">
            <div className="flex flex-col justify-center items-center">
              <h1 className="title text-center uppercase text-x26 md:text-x40 font-sans">
                {primaryData?.title?.text}
              </h1>
            </div>
          </div>

          <div className="datatime-wrapp flex justify-center items-center font-serif text-black-900 text-base md:text-base opacity-50">
            <div className="flex items-center">
              <DateIcon className="mr-2" />
              {primaryData?.publishdate}
            </div>
            <div className="ml-7 flex items-center">
              <TimeIcon className="mr-2" />8 min read
            </div>
          </div>

          <div className="flex justify-center flex-col items-center mt-8">
            <div className="flex items-center">
              <img
                src={
                  primaryData?.author?.document?.data.body[0]?.primary?.avatar
                    .url
                }
                className="member-avatar"
                alt="eminent member"
              />
            </div>
          </div>

          <div className="flex justify-center flex-col items-center font-serif text-black-900 text-base">
            <h5 className="flex items-center mt-3 capitalize text-base md:text-lg">
              {primaryData?.author?.document?.data.body[0]?.primary?.name.text}
            </h5>
            <div className="flex items-center mt-1 capitalize text-base md:text-base opacity-60">
              {primaryData?.author?.document?.data.body[0]?.primary?.role.text}
            </div>
          </div>
        </Container>
      </div>
    </>
  )
}

export const fragments = graphql`
  fragment SliceBlogPostInformation on PrismicBlogPostDataBodyMainBlogInformation {
    ... on PrismicBlogPostDataBodyMainBlogInformation {
      id
      slice_type
      primary {
        title {
          html
          text
        }
        category
        card_image {
          thumbnails {
            Mobile {
              gatsbyImageData(layout: FULL_WIDTH)
            }
            Tablet {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          alt
          gatsbyImageData(layout: FULL_WIDTH)
        }
        card_description
        publishdate
        author {
          slug
          document {
            ... on PrismicMember {
              id
              data {
                body {
                  ... on PrismicMemberDataBodyMember {
                    primary {
                      name {
                        text
                      }
                      role {
                        text
                      }
                      avatar {
                        url
                        thumbnails {
                          Mobile {
                            url
                          }
                          Tablet {
                            url
                          }
                        }
                        alt
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
