import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import Container from '../../container'
import Title from '../../title'

export const ServiceDesignHero = ({ slice }) => {
  const { primary: data } = useMemo(() => slice, [slice])

  return (
    <>
      <div
        className="service-design-hero-top"
        style={{ backgroundColor: data?.backgroundcolor }}
      >
        <Container>
          {/* <div className="mx-4 md:mx-3 flex flex-col xs:grid grid-cols-12 gap-x-2 md:gap-x-5">
            <div className="col-start-3 col-span-7 flex flex-col justify-start xs:justify-center"> */}
              <Title className="services-design-title text-center text-black-900">
                <h1>
                  <span className={`title ${data?.title2?.text  ? "firstTitle" : ""}`} dangerouslySetInnerHTML={{ __html: data?.title1?.text }} />
                  {data?.title2?.text && (
                    <React.Fragment>
                      <br/>
                      <span className="splitTitle" dangerouslySetInnerHTML={{ __html: data.title2.text }} />
                    </React.Fragment>
                  )}
                </h1>
              </Title>
            {/* </div>
          </div> */}

          <div className="mx-4 flex justify-start xs:justify-center items-center">
            <div className="haigrast-text font-mono text-gold-900 text-x40 sm:text-x64 md:text-x80 text-center">
              {data?.subtitle?.text}
            </div>
          </div>

          <div className="mx-4 tags flex justify-start xs:justify-center items-center mt-6 xl:mt-10">
            <div className="middle-line" />
            <div className="font-serif text-base text-gold-900">
              {data?.tag?.text}
            </div>
          </div>

          <div className="description-wrapp mx-4 flex justify-start xs:justify-center items-center my-8 md:my-10">
            <div className="font-serif text-base xl:text-lg text-black-900 description">
              {data?.description?.text}
            </div>
          </div>
        </Container>
      </div>

      <div className="service-design-hero-bottom mx-4 sm:mx-8 md:mx-0">
        <Container>
          <div className="images-wrapp mx-0 md:mx-3 flex xs:grid grid-rows-1 grid-cols-12 gap-x-2 md:gap-x-5">
            <div className="image-big-wrapp col-start-1 col-span-9 2xl:col-start-1 2xl:col-span-10 row-start-1 flex flex-col w-full">
              <GatsbyImage
                image={data?.big_image.gatsbyImageData}
                className="w-full object-cover object-center"
                srcSet={data?.big_image.gatsbyImageData.srcSet}
                placeholder="blurred"
                alt={
                  data?.big_image?.alt || 'eminent services design '
                }
              />
            </div>
            <div className="hidden image-mid-wrapp col-start-9 col-span-5 row-start-1 xs:flex flex-col">
              <GatsbyImage
                image={data?.medium_image?.gatsbyImageData}
                className="w-full object-cover object-center"
                srcSet={data?.medium_image?.gatsbyImageData.srcSet}
                placeholder="blurred"
                alt={
                  data?.medium_image?.alt || 'eminent services design '
                }
              />
            </div>
            <div className="hidden image-small-wrapp col-start-12 col-span-1 row-start-1 2xl:flex flex-col">
              <GatsbyImage
                image={data?.small_image?.gatsbyImageData}
                srcSet={data?.small_image?.gatsbyImageData.srcSet}
                placeholder="blurred"
                className="object-cover object-center"
                alt={
                  data?.small_image?.alt
                    ? data?.small_image?.alt
                    : 'eminent services design '
                }
              />
            </div>
          </div>
        </Container>
      </div>
    </>
  )
}

export const fragments = graphql`
  fragment SliceServicedesignHero on PrismicServicedesigntemplateDataBodyServiceHero {
    ... on PrismicServicedesigntemplateDataBodyServiceHero {
      id
      slice_type
      primary {
        big_image {
          alt
          gatsbyImageData(
            placeholder: BLURRED
            imgixParams: {fm: "avif", w: 1200}
          )
        }
        description {
          text
        }
        medium_image {
          alt
          gatsbyImageData(
            placeholder: BLURRED
            imgixParams: {fm: "avif", w: 1200}
          )
        }
        small_image {
          alt
          gatsbyImageData(
            placeholder: BLURRED
            imgixParams: {fm: "avif", w: 1200}
          )
        }
        subtitle {
          text
        }
        tag {
          text
        }
        title1 {
          html
          text
        }
        title2 {
          html
          text
        }
        backgroundcolor
      }
    }
  }
`
