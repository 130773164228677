import React, { useState } from 'react';
import CloseIcon from '../svgIcons/closeIcon';
import CallIcon from '../svgIcons/callIcon';
import Link from '../link';
import LocationPinIcon from '../svgIcons/locationPinIcon';
import EmailIcon from '../svgIcons/emailIcon';
import Logo from '../svgIcons/logo';

const menu = [
  {
    name: 'Home',
    link: '/'
  },
  {
    name: 'Services',
    link: '/services',
  },
  {
    name: 'Projects',
    link: '/projects',
  },
  {
    name: 'Gallery',
    link: '/gallery',
  },
  {
    name: 'About',
    link: '/about-us',
    children: [
      { name: 'Process', link: '/our-process' },
      { name: 'Team', link: '/team' },
      { name: 'Inspiring Design', link: '/blog' }
    ],
  },
  {
    name: 'Contact',
    link: '/contact-us',
  },
];

export const Hamburger = ({ className, setNavOpen, navOpen }) => {
  const [activeMenu, setActiveMenu] = useState(null);

  const toggleSubMenu = (index) => {
    setActiveMenu(activeMenu === index ? null : index);
  };

  return (
    <div className={`fixed top-0 bg-black-900 z-50 transform transition-transform duration-300 ease-out ${navOpen ? 'translate-x-0' : 'translate-x-full'} w-full lg:hidden`}>
      <header className={`${className || ''} hamburger z-40 flex flex-col`}>
        <div className="font-serif flex justify-between items-center text-white">
          <div className="flex items-center w-1/2">
            <CloseIcon
              className="cursor-pointer close-icon"
              onClick={() => setNavOpen(!navOpen)}
            />
          </div>
          <div className="logo-wrapp flex-grow flex items-center justify-center w-max">
            <Link to="/" aria-label="Home" tabIndex="-1">
              <Logo className="hamburger-logo" />
            </Link>
          </div>
          <div className="flex flex-col items-end justify-center w-1/2"></div>
        </div>

        <div className="hamburger-menu-t font-sans text-white flex justify-between items-stretch text-x32 sm:text-x32 uppercase flex-col">
          <div className="menu-wrapp flex flex-col justify-center items-center">
            {menu.map((item, index) => (
              <React.Fragment key={index}>
                <div className="mobileNavItem">
                  <Link
                    to={item.link}
                    className="mx-3 lg:mx-4 2xl:mx-8 cursor-pointer w-max hover:text-gold-900"
                    activeClassName="hamburger-active-menu"
                    tabIndex="-1"
                  >
                    {item.name}
                  </Link>
                  {item.children && (
                    <svg onClick={() => toggleSubMenu(index)} fill="#FFF" height="20" width="20" viewBox="0 0 22 22" aria-hidden="true" focusable="false" className={`mobileNavCaret ${activeMenu === index ? 'open' : ''} css-tj5bde-Svg`}>
                      <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                    </svg>
                  )}
                </div>
                {item.children && activeMenu === index && (
                  <div className="mobileSubnav flex flex-col justify-center items-center text-[20px]">
                    {item.children.map((subItem, subIndex) => (
                      <Link key={subIndex} to={subItem.link} className="lg:mx-2 2xl:mx-6 cursor-pointer w-max hover:text-gold-900" ariaLabel={subItem.name}>
                        {subItem.name}
                      </Link>
                    ))}
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>

        <div className="menu-info-wrapp w-full flex flex-col justify-center text-white font-serif">
          <div className="flex flex-col md:flex-row justify-between w-full">
            <div className="header-phone flex justify-center items-center my-1">
              <a href={`tel: 612-767-1242`} tabIndex="-1">
                <span className="flex items-center">
                  <CallIcon className="mr-2" />
                  612-767-1242
                </span>
              </a>
            </div>

            <div className="header-location flex justify-center items-center my-1">
              <LocationPinIcon className="mr-2" />
              11140 Hwy 55 Suite C, Plymouth, MN 55441
            </div>

            <div className="header-email flex justify-center items-center my-1">
              <a href={`mailto: Collaborate@eminentid.com`} tabIndex="-1">
                <span className="flex items-center">
                  <EmailIcon className="mr-2" />
                  Collaborate@eminentid.com
                </span>
              </a>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};
