import React from 'react'

const Title = ({ children, className, type = 'ivarDisplay' }) => {
  const styles =
    type === 'ivarDisplay'
      ? 'text-x40 sm:text-x60 lg:text-x80 2xl:text-x100 font-sans uppercase'
      : type === 'haigrast' &&
      'text-4xl sm:text-x50 lg:text-x70 2xl:text-x90 font-mono text-gold-900 text-center'

  return <div className={`${styles} ${className}`}>{children}</div>
}

export default Title
