import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import { PrismicImgCaptions } from '../../prismicImageCaptions'

export const ProjectImgCaptionsSection = ({ slice }) => {
  const { primary: primaryData } = useMemo(() => slice, [slice])

  return (
    <PrismicImgCaptions
      width={primaryData.width}
      type={primaryData.type1}
      image={primaryData?.image}
      title={primaryData?.h2_h3}
      content={primaryData?.content}
      callout={primaryData?.service_callout}
      caption={primaryData?.caption}
    />
  )
}

export const fragments = graphql`
  fragment SliceProjectImgCaptionsSection on PrismicProjectTemplateDataBodyImageWithCaptions {
    ... on PrismicProjectTemplateDataBodyImageWithCaptions {
      id
      slice_type
      primary {
        type1
        width
        caption
        h2_h3 {
          html
        }
        content {
          html
        }
        image {
          alt
          gatsbyImageData
        }
        service_callout
      }
    }
  }
`
