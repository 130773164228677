import React from 'react'

import { ProjectImage } from '../sections/project-image'
import { GallerySection } from '../sections/gallery'
import { ProjectContent } from '../sections/project-content'
import { ProjectImgCardSection } from '../sections/project-imgcards'
import { ProjectImgCaptionsSection } from '../sections/project-img-captions'
import { ProjectNextButton } from '../sections/project-next-button'
import { ProjectContentWithBgImgSection } from '../sections/project-content-bgImg'
import { ProjectCallout } from '../sections/project-callout'
import { ProjectDivider } from '../sections/project-divider'
import { ProjectTwoColumn } from '../sections/project-two-column'

const ProjectSlices = ({ classNames = {}, slices, publishDate, type }) => {
  return slices.map((slice) => {
    const key = slice.id
    switch (slice.slice_type) {
      case 'gallery':
        return <GallerySection slice={slice} key={key} />

      case 'image':
        return <ProjectImage slice={slice} key={key} />

      case 'content':
        return <ProjectContent slice={slice} key={key} />

      case 'callout':
        return <ProjectCallout slice={slice} key={key} />

      case 'divider':
        return <ProjectDivider slice={slice} key={key} />

      case 'image_card_section':
        return <ProjectImgCardSection slice={slice} key={key} />

      case 'image_with_captions':
        return <ProjectImgCaptionsSection slice={slice} key={key} />

      case 'contentwithbgimage':
        return <ProjectContentWithBgImgSection slice={slice} key={key} />

      case 'next_button':
        return <ProjectNextButton slice={slice} key={key} />

      case 'two_column':
        return <ProjectTwoColumn slice={slice} key={key} />

      default:
        return null
    }
  })
}

export default ProjectSlices
