import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import Container from '../../container'
import Title from '../../title'
import RectangleButton from '../../rectangleButton'

export const ContactUs = ({ slice }) => {
  const { primary: primaryData, items: itemsData } = useMemo(
    () => slice,
    [slice],
  )

  return (
    <div
      className="contactus px-4 pd:mx-8"
      style={{ backgroundColor: primaryData?.backgroundcolor }}
    >
      <Container>
        <div className="flex justify-center items-center">
          <Title className="title text-center text-black-900">
            {primaryData?.title1?.text}
          </Title>
        </div>

        <div className="description-wrapp flex justify-center items-center">
          <div className="description font-serif text-base md:text-lg text-black-900 text-center ">
            {primaryData?.description?.text}
          </div>
        </div>

        {primaryData?.bottom_text && (
          <>
            <div className="max-w-[560px] mx-auto mt-4 hidden md:block font-serif text-base text-black-900 text-left">
              {primaryData?.bottom_text?.text}
            </div>
          </>
        )}
        <div className="mt-8 flex justify-center">
          <RectangleButton title="Contact Us" link="/contact-us" />
        </div>
      </Container>
    </div>
  )
}

export const fragments = graphql`
  fragment SliceContactus on PrismicServicedesigntemplateDataBodyContactUs {
    ... on PrismicServicedesigntemplateDataBodyContactUs {
      id
      slice_type
      primary {
        title1 {
          text
        }
        description {
          text
        }
        bottom_text {
          text
        }
        backgroundcolor
      }
    }
  }
`
