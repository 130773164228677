import React from 'react'

export const PrismicCallout = ({ callout, position }) => {
  return (
    <div className="w-full font-serif text-base text-gold-900 mx-auto">
      <div
        className={`flex items-center ${
          position === 'Center'
            ? 'justify-center'
            : position === 'Right Justify' && 'justify-end'
        }`}
      >
        <div className="w-[72px] border-solid border-t border-gold-900 mr-4" />
        {callout}
      </div>
    </div>
  )
}
