import React from 'react'

const ButtonArrowIcon = ({
  className,
  width = '10',
  height = '9',
  color = '#996515',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M1 8.5L5 4.5L1 1" stroke={color} />
      <path d="M5 8.5L9 4.5L5 1" stroke={color} />
    </svg>
  )
}

export default ButtonArrowIcon
