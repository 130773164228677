import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import { Layout } from '../components/Layout'
import BlogPostSlices from '../components/slices/blog-post'

const BlogTemplate = ({
  data: {
    prismicBlogPost: {
      data: { body: slices },
    },
  },
}) => {
  return (
    <Layout>
      <BlogPostSlices slices={slices} />
    </Layout>
  )
}

export const Head = ({ data, location }) => {
  const {
    prismicBlogPost: {
      data: { seo_title, seo_keywords, seo_image, seo_description },
    },
  } = data
  const metadata = {
    title: seo_title,
    description: seo_description,
    pathname: location.pathname,
    ogTagImage: seo_image?.url,
    keywords: seo_keywords,
  }
  return <SEO metadata={metadata} />
}

export default BlogTemplate

export const BlogPostQuery = graphql`
  query BlogPost($uid: String!) {
    prismicBlogPost(uid: { eq: $uid }) {
      id
      uid
      type
      data {
        seo_title
        seo_keywords
        seo_image {
          url
        }
        seo_description
        body {
          ...SliceBlogPostInformation
          ...SliceBlogPostGallery
          ...SliceBlogImageCarousel
          ...BlogPostImageGroup
          ...BlogPostImages
          ...BlogPostContent
          ...SliceBlogPostProjectsCarousel
          ...SubscribeBox
          ...SliceBlogPostCallout
          ...SliceBlogPostDivider
          ...SliceBlogPostImgCaptions
          ...SliceBlogPostImgCards
          ...SliceBlogPostTwoColumn
        }
      }
    }
  }
`
