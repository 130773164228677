import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import Container from '../../container'

export const ServiceDesignTextbox = ({ slice }) => {
  const { primary: primaryData } = useMemo(() => slice, [slice])

  return (
    <div className="service-design-textbox">
      <Container>
        {primaryData?.title1?.html && (
          <div className="mx-4 md:mx-3 flex justify-center items-center">
            <div
              className="title text-x26 sm:text-x32 xl:text-x40 text-center text-black-900 uppercase"
              dangerouslySetInnerHTML={{
                __html: primaryData?.title1?.html,
              }}
            />
          </div>
        )}

        {primaryData?.description?.html && (
          <div className="description-wrapp mx-4 flex justify-center items-center mt-5 md:mt-6 xl:mt-10">
            <div
              className="font-serif text-base text-black-900 text-center description"
              dangerouslySetInnerHTML={{
                __html: primaryData?.description?.html,
              }}
            />
          </div>
        )}
      </Container>
    </div>
  )
}

export const fragments = graphql`
  fragment SliceServicedesignTextbox on PrismicServicedesigntemplateDataBodyTextBox {
    ... on PrismicServicedesigntemplateDataBodyTextBox {
      id
      slice_type
      primary {
        title1 {
          html
        }
        description {
          html
        }
      }
    }
  }
`
