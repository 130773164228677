import React, { useMemo, useState } from 'react'
import { graphql } from 'gatsby'
import Container from '../../container'
import EmailIcon from '../../svgIcons/emailIcon'

import { validateEmail } from '../../../utils/validation'

export const SubscribeCard = ({ slice }) => {
  const { primary: primaryData } = useMemo(() => slice, [slice])

  const [data, setData] = useState({
    email: '',
  })

  const [response, setResponse] = useState(null)

  const [errMsg, setErrorMsg] = useState({
    email: null,
  })

  const validate = (value, name) => {
    let msg = null

    if (name === 'email') {
      msg = validateEmail(value)
    }

    return msg
  }

  const handleChangeInput = (e, label) => {
    if (response) setResponse(null)
    setData({
      ...data,
      [label]: e.target.value,
    })
  }

  const handleBlur = (e, label) => {
    if (response) setResponse(null)
    const invalid = validate(e.target.value, label)

    setErrorMsg({
      ...errMsg,
      [label]: invalid,
    })
  }

  const submit = () => {
    const emailInvalid = validate(data.email, 'email')
    if (!emailInvalid) {
      sendFormRequest()
    } else {
      setErrorMsg({
        ...errMsg,
        email: emailInvalid,
      })
    }
  }

  const sendFormRequest = () => {
    fetch(`https://formcarry.com/s/XRXgGL-i7C4`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(function (response) {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        return response.json()
      })
      .then(function (data) {
        setResponse(data.message)
      })
      .catch(function (error) {
        setResponse(error.message)
      })
  }

  return (
    <div className="subscribe-card">
      <Container>
        <div
          className="subscribe-card-container mx-4 xl:mx-0"
          style={{ backgroundImage: `url("/subscribe.png")` }}
        >
          <div className="mx-0 md:mx-3 flex flex-col justify-center items-center">
            <div className="title text-center uppercase text-x22 md:text-x26 font-sans">
              {primaryData?.title?.text}
            </div>
            <div className="description text-center text-base font-serif">
              {primaryData?.description?.text}
            </div>
          </div>

          <div className="form px-4 relative">
            {response && (
              <div className="text-base text-purple-900 font-serif text-center mb-4">
                {response}!
              </div>
            )}
            <div className="flex flex-col sm:flex-row form-wrapp justify-between items-center">
              <div className="relative w-full">
                <div className="input-wrapp flex justify-between items-center relative w-full">
                  <input
                    className={`appearance-none bg-white border border-solid text-base font-serif w-full py-2 text-black-900 leading-normal focus:outline-none focus:shadow-outline ${
                      errMsg.email
                        ? `border-red-600 placeholder-red-600`
                        : `border-black-200 placeholder-black-900`
                    }`}
                    id="email"
                    type="text"
                    placeholder="Email"
                    onChange={(e) => handleChangeInput(e, 'email')}
                    onBlur={(e) => handleBlur(e, 'email')}
                  />
                  <EmailIcon
                    className="input-right-icon"
                    color={errMsg.email ? 'red' : 'black'}
                  />
                </div>
              </div>

              <div
                className="rectangle-submit-button cursor-pointer font-serif text-base bg-gold-900 text-white"
                onClick={submit}
                role="button"
                tabIndex={0}
                onKeyDown={submit}
              >
                <div className="flex justify-center items-baseline">
                  <span>Submit</span>
                </div>
              </div>
            </div>
            <div className="hidden sm:flex error-wrapp">
              {errMsg.email && (
                <div className="text-xs text-red-600 font-serif ml-1">
                  {errMsg.email}
                </div>
              )}
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export const fragments = graphql`
  fragment SubscribeBox on PrismicBlogPostDataBodySubscribeBox {
    ... on PrismicBlogPostDataBodySubscribeBox {
      id
      slice_type
      primary {
        title {
          text
        }
        description {
          text
        }
      }
    }
  }
`
