import React from 'react'

const TimeIcon = ({ className, width = '20', height = '21' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g opacity="0.5">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 2.25C5.44365 2.25 1.75 5.94365 1.75 10.5C1.75 15.0563 5.44365 18.75 10 18.75C14.5563 18.75 18.25 15.0563 18.25 10.5C18.25 5.94365 14.5563 2.25 10 2.25ZM3.25 10.5C3.25 6.77208 6.27208 3.75 10 3.75C13.7279 3.75 16.75 6.77208 16.75 10.5C16.75 14.2279 13.7279 17.25 10 17.25C6.27208 17.25 3.25 14.2279 3.25 10.5ZM10.75 7.16667C10.75 6.75245 10.4142 6.41667 10 6.41667C9.58579 6.41667 9.25 6.75245 9.25 7.16667V10.5C9.25 10.7508 9.37533 10.9849 9.58397 11.124L12.084 12.7907C12.4286 13.0205 12.8943 12.9273 13.124 12.5827C13.3538 12.238 13.2607 11.7724 12.916 11.5426L10.75 10.0986V7.16667Z"
          fill="#34342E"
        />
      </g>
    </svg>
  )
}

export default TimeIcon
