import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import { PrismicCallout } from '../../prismicCallout'

export const ProjectCallout = ({ slice }) => {
  const { primary: primaryData } = useMemo(() => slice, [slice])

  return (
    <PrismicCallout
      callout={primaryData?.callout}
      position={primaryData?.position}
    />
  )
}

export const fragments = graphql`
  fragment SliceProjectCallout on PrismicProjectTemplateDataBodyCallout {
    ... on PrismicProjectTemplateDataBodyCallout {
      id
      slice_type
      primary {
        callout
        position
      }
    }
  }
`
