import React from 'react'
import { BlogImageCarousel } from '../sections/blog-image-carousel'
import { BlogPostContent } from '../sections/blog-post-content'
import { BlogPostHero } from '../sections/blog-post-hero'
import { BlogPostImage } from '../sections/blog-post-image'
import { BlogPostImageGroup } from '../sections/blog-post-image-group'
import { GallerySection } from '../sections/gallery'
import { SpotlightsSlider } from '../sections/spotlights-slider'
import { SubscribeCard } from '../sections/subscribe-card'
import { BlogPostCallout } from '../sections/blog-post-callout'
import { BlogPostDivider } from '../sections/blog-divider'
import { BlogPostImgCaptionsSection } from '../sections/blog-img-captions'
import { BlogPostImgCards } from '../sections/blog-post-imgcards'
import { BlogPostTwoColumn } from '../sections/blog-post-two-column'

const BlogPostSlices = ({ classNames = {}, slices }) => {
  return slices.map((slice) => {
    const key = slice.id

    switch (slice.slice_type) {
      case 'main_blog_information':
        return <BlogPostHero slice={slice} key={key} />

      case 'image':
        return <BlogPostImage slice={slice} key={key} />

      case 'content':
        return <BlogPostContent slice={slice} key={key} />

      case 'callout':
        return <BlogPostCallout slice={slice} key={key} />

      case 'divider':
        return <BlogPostDivider slice={slice} key={key} />

      case 'image_group':
        return <BlogPostImageGroup slice={slice} key={key} />

      case 'image_carousel':
        return <BlogImageCarousel slice={slice} key={key} />

      case 'gallery':
        return <GallerySection slice={slice} key={key} />

      case 'services_carousel':
        return <SpotlightsSlider slice={slice} key={key} />

      case 'subscribe_box':
        return <SubscribeCard slice={slice} key={key} />

      case 'image_with_captions':
        return <BlogPostImgCaptionsSection slice={slice} key={key} />

      case 'image_card_section':
        return <BlogPostImgCards slice={slice} key={key} />

      case 'two_column':
        return <BlogPostTwoColumn slice={slice} key={key} />

      default:
        return null
    }
  })
}

export default BlogPostSlices
