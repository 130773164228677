module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Eminent","short_name":"Eminent","description":"Eminent Interior Design","lang":"en_US","start_url":"/","icon":"/opt/build/repo/src/images/android-icon-192x192.png","icons":[{"src":"/opt/build/repo/src/images/android-icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/opt/build/repo/src/images/android-icon-96x96.png","sizes":"96x96","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d89a42c5d04cf878c57c5d7f7c403228"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-43RWCBZG5Q"],"gtagConfig":{}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
