import React from 'react'
import ButtonArrowIcon from '../svgIcons/buttonArrowIcon'
import Link from '../link'

const RectangleButton = ({ className, title, link }) => {
  return (
    <Link to={link}>
      <div
        className={
          `${className} rectangle-button cursor-pointer font-serif text-base md:text-base text-gold-900`}
      >
        <div className="flex justify-center items-baseline">
          <span>{title}</span>
          <ButtonArrowIcon className="ml-2" />
        </div>
      </div>
    </Link>
  )
}

export default RectangleButton
