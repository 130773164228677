import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import { RichTextContent } from '../../richtext-content'

export const BlogPostContent = ({ slice }) => {
  const { primary: primaryData, items } = useMemo(() => slice, [slice])

  return <RichTextContent items={items} width={primaryData.width} />
}

export const fragments = graphql`
  fragment BlogPostContent on PrismicBlogPostDataBodyContent {
    ... on PrismicBlogPostDataBodyContent {
      id
      slice_type
      primary {
        width
      }
      items {
        description {
          html
        }
        type1
        author1
      }
    }
  }
`
