import React from 'react'

const LineArrowIcon = ({ className, width = '73', height = '14' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 73 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0 7.19355H72M72 7.19355L66 1M72 7.19355L66 13"
        stroke="#996515"
      />
    </svg>
  )
}

export default LineArrowIcon
