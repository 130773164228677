import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import { PrismicImageCards } from '../../prismicImageCards'

export const BlogPostImgCards = ({ slice }) => {
  const { items: itemsData } = useMemo(() => slice, [slice])

  return <PrismicImageCards cards={itemsData} />
}

export const fragments = graphql`
  fragment SliceBlogPostImgCards on PrismicBlogPostDataBodyImageCardSection {
    ... on PrismicBlogPostDataBodyImageCardSection {
      id
      slice_type
      items {
        caption
        description {
          html
          text
        }
        image {
          thumbnails {
            Mobile {
              gatsbyImageData(imgixParams: { fm: "webp" })
            }
            Tablet {
              gatsbyImageData(imgixParams: { fm: "webp" })
            }
          }
          alt
          gatsbyImageData(imgixParams: { fm: "webp" })
        }
        title {
          html
          text
        }
      }
    }
  }
`
