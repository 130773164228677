import React from 'react'

import { MemberHero } from '../sections/member-hero'
import { SpotlightsSlider } from '../sections/spotlights-slider'

const MemberSlices = ({ classNames = {}, slices }) => {
  return slices.map((slice) => {
    const key = slice.id

    switch (slice.slice_type) {
      case 'member':
        return <MemberHero slice={slice} key={key} />

      case 'member_slider':
        return <SpotlightsSlider slice={slice} key={key} />

      default:
        return null
    }
  })
}

export default MemberSlices
