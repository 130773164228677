import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import Title from '../../title'
import Link from '../../link'
// import GallerySlickSlider from '../../gallerySlickSlider'

export const GallerySection = ({ slice }) => {
  const { primary } = useMemo(() => slice, [slice])
  return (
    <div className="px-0 py-14 sm:pb-20 lg:pb-24 lg:pt-0 bg-beige-900 text-black-900">
      {/* <GallerySlickSlider /> */}
      <div className="gallery-wrapp  flex justify-center flex-col items-center">
        <p className="uppercase font-serif text-base sm:text-base">
          {primary?.head?.text}
        </p>
        <div className="pb-4 border-b border-gold-900">
          <Link
            to={primary?.link?.uid ? `/${primary?.link?.uid}` : `/projects`}
          >
            <Title className="text-center mt-5 text-gold-900">
              {primary?.h2?.text || primary?.title1?.text}
            </Title>
          </Link>
        </div>
      </div>
    </div>
  )
}

export const fragments = graphql`
  fragment SliceHomeGallery on PrismicHomepageDataBodyGallery {
    ... on PrismicHomepageDataBodyGallery {
      id
      slice_type
      primary {
        h2 {
          text
        }
        head {
          text
        }
        link {
          uid
        }
      }
    }
  }
  fragment SliceServicedesignGallery on PrismicServicedesigntemplateDataBodyGallery {
    ... on PrismicServicedesigntemplateDataBodyGallery {
      id
      slice_type
      primary {
        title1 {
          text
        }
        head {
          text
        }
      }
    }
  }

  fragment SliceProjectsGallery on PrismicProjectspageDataBodyGallery {
    ... on PrismicProjectspageDataBodyGallery {
      id
      slice_type
      primary {
        title1 {
          text
        }
        head {
          text
        }
      }
    }
  }
  fragment SliceProjectGallery on PrismicProjectTemplateDataBodyGallery {
    ... on PrismicProjectTemplateDataBodyGallery {
      id
      slice_type
      primary {
        title1 {
          text
        }
        head {
          text
        }
      }
    }
  }

  fragment SliceBlogPostGallery on PrismicBlogPostDataBodyGallery {
    ... on PrismicBlogPostDataBodyGallery {
      id
      slice_type
      primary {
        title1 {
          text
        }
        head {
          text
        }
      }
    }
  }
`
