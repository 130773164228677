import React from 'react'

const DoubleArrowIcon = ({
  className,
  width = '8',
  height = '8',
  stroke = '#34342E',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M1 7L4 3.8L1 1" stroke={stroke} />
      <path d="M4 7L7 3.8L4 1" stroke={stroke} />
    </svg>
  )
}

export default DoubleArrowIcon
