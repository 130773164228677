export const compare = (a, b) => {
  if (
    Number(a.filename.split('-').pop()) < Number(b.filename.split('-').pop())
  ) {
    return -1
  }
  if (
    Number(a.filename.split('-').pop()) > Number(b.filename.split('-').pop())
  ) {
    return 1
  }
  return 0
}

export const chunkArray = (array, size) => {
  const chunkedArray = []
  let index = 0

  while (index < array.length) {
    chunkedArray.push(array.slice(index, index + size))
    index += size
  }
  return chunkedArray
}

