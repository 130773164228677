import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import Container from '../container'
import Caption from '../caption'

export const PrismicImgCaptions = ({
  image,
  caption,
  title,
  content,
  type,
  callout,
  width,
}) => {
  return (
    <div className="py-10 image_with_captions text-black-900">
      <Container>
        <div
          className={`block px-4 xs:grid w-full items-start grid-rows-1 grid-cols-12 gap-x-2 md:gap-x-4`}
        >
          <div
            className={`${
              width === 'wide'
                ? 'col-start-1 col-span-12'
                : 'col-start-1 col-span-12 md:col-start-2 md:col-span-10'
            }`}
          >
            <div className="flex flex-col xs:flex-row justify-start font-serif text-black-900">
              <div
                className={`flex ${
                  type === 'vertical'
                    ? 'flex-col sm:flex-row sm:gap-4 md:gap-8'
                    : 'flex-col'
                }`}
              >
                <div className="flex-1 relative">
                  <GatsbyImage
                    image={image.gatsbyImageData}
                    className="w-full object-cover object-center"
                    alt={image?.alt ? image?.alt : 'eminent'}
                  />
                  {caption && <Caption>{caption}</Caption>}
                </div>
                <div
                  className={`flex flex-1 ${
                    type === 'vertical'
                      ? 'flex-col-reverse justify-end items-end'
                      : 'flex-col md:flex-row'
                  }`}
                >
                  <div>
                    <div
                      className="text-2xl lg:text-[32px] text-black-900 uppercase mt-10 mb-6"
                      dangerouslySetInnerHTML={{
                        __html: title?.html,
                      }}
                    />

                    <div
                      className="text-base md:text-xl text-left text-black-700"
                      dangerouslySetInnerHTML={{
                        __html: content?.html,
                      }}
                    />
                  </div>
                  {callout && (
                    <div
                      className={`min-w-fit flex items-start ${
                        type === 'vertical' ? 'mt-4' : 'mt-1 md:translate-y-16'
                      } `}
                    >
                      <div className="mx-4 tags flex justify-end md:justify-center items-center">
                        <div className="w-24 border-t border-solid border-gold-900 ml-6 mr-4" />
                        <div className="font-serif text-sm text-gold-900 min-w-fit">
                          {callout}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}
