import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import Container from '../../container'
import Link from '../../link'

import LineArrowIcon from '../../svgIcons/lineArrowIcon'

export const ProjectNextButton = ({ slice }) => {
  const { primary: primaryData } = useMemo(() => slice, [slice])

  return (
    <>
      {primaryData?.link?.url && (
        <div className="project-next-button text-black-900">
          <Container>
            <h3 className="title mt-20 md:mt-32 font-sans uppercase text-x32 sm:text-6xl md:text-x60 xl:text-x90 text-black-900 text-center">
              {primaryData?.title?.text}
            </h3>
            <div className="relative flex justify-center">
              <Link to={primaryData?.link?.url} className="cursor-pointer">
                <div className="circle-button mt-15 relative cursor-pointer">
                  <span className="w-full h-full flex justify-center items-center text-base font-serif text-gold-900">
                    Next Project
                  </span>
                  <span className="lineArrowIcon absolute top-0 left-0 w-full h-full flex justify-center items-center">
                    <LineArrowIcon />
                  </span>
                </div>
              </Link>
            </div>
          </Container>
        </div>
      )}
    </>
  )
}

export const fragments = graphql`
  fragment SliceProjectNextButton on PrismicProjectTemplateDataBodyNextButton {
    ... on PrismicProjectTemplateDataBodyNextButton {
      id
      slice_type
      primary {
        link {
          url
          slug
        }
        title {
          text
        }
      }
    }
  }
`
