import React, { useEffect, useState } from 'react';
import MenuIcon from '../svgIcons/menuIcon';
import Link from '../link';
import Logo from '../svgIcons/logo';
import useScreenWidth from '../../hooks/useScreenWidth';

const menu = [
  {
    name: 'Services',
    link: '/services',
  },
  {
    name: 'Projects',
    link: '/projects',
  },
  {
    name: 'Gallery',
    link: '/gallery',
  },
  {
    name: 'About',
    link: '/about-us',
    children: [
      { name: 'Process', link: '/our-process' },
      { name: 'Team', link: '/team' },
      { name: 'Inspiring Design', link: '/blog' }
    ],
  },
  {
    name: 'Contact',
    link: '/contact-us',
  },
];

export const Header = ({ className, setNavOpen, navOpen, isMobile }) => {
  const screenWidth = useScreenWidth();
  const [activeMenu, setActiveMenu] = useState(null);

  const toggleSubNav = (name) => {
    if (activeMenu === name) {
      setActiveMenu(null);
    } else {
      setActiveMenu(name);
    }
  };

  return (
    <div className="top-0 sticky z-40 bg-white">
      <header className={`${className} header z-40`}>
        <div className="font-serif text-black flex justify-between items-stretch text-black-900 text-base">
          <div className="flex items-center w-1/2">
            {isMobile && !navOpen && (
              <MenuIcon
                className="cursor-pointer"
                onClick={() => setNavOpen(!navOpen)}
              />
            )}
            {!isMobile && (
              <div className="flex justify-center items-center ml-2">
                {menu.map((item, index) => (
                  <div className="navItem" key={index} onMouseEnter={() => toggleSubNav(item.name)} onMouseLeave={() => setActiveMenu(null)}>
                    <Link
                      to={item.link}
                      className="mx-2 xl:mx-6 cursor-pointer w-max"
                      ariaLabel={item.name}
                    >
                      {item.name}
                    </Link>
                    {item.children && (
                      <svg fill="#000" height="20" width="20" viewBox="0 0 22 22" aria-hidden="true" focusable="false" className="-ms-2 xl:-ms-6 css-tj5bde-Svg"><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
                    )}
                    {activeMenu === item.name && item.children && (
                      <div className="subnav">
                        {item.children.map((subItem, subIndex) => (
                          <Link
                            key={subIndex}
                            to={subItem.link}
                            className="lg:mx-2 2xl:mx-6 cursor-pointer w-max"
                            ariaLabel={subItem.name}
                          >
                            {subItem.name}
                          </Link>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
          {!navOpen && (
            <div className="logo-wrapp flex-grow flex items-center justify-center w-max">
              <Link to="/" ariaLabel="Home">
                <Logo />
              </Link>
            </div>
          )}

          <div className="flex flex-col items-end justify-center w-1/2"></div>
        </div>
      </header>
    </div>
  );
};
