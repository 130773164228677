import React from 'react'

const MenuIcon = ({ className, width = '36', height = '15', onClick }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <rect width={width} height="1" fill="#34342E" />
      <rect y="14" width={width} height="1" fill="#34342E" />
      <rect y="7" width="24" height="1" fill="#34342E" />
    </svg>
  )
}

export default MenuIcon
