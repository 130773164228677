import React from 'react'
import Container from '../container'
import { PrismicCallout } from '../prismicCallout'

export const RichTextContent = ({ items, width }) => {
  return (
    <div className="py-10 richtext-content text-black-900">
      <Container>
        <div
          className={`mx-4 xs:grid grid-rows-1 grid-cols-12 gap-x-2 md:gap-x-4`}
        >
          <div
            className={`flex justify-start flex-col ${
              width === 'wide'
                ? 'col-start-1 col-span-12'
                : 'col-start-1 col-span-12 lg:col-start-2 lg:col-span-10'
            }`}
          >
            {items &&
              items.map((item, index) => {
                return (
                  <div className="my-3" key={index}>
                    <div
                      className={`${
                        item.type1 === 'LeftLine'
                          ? 'left-line'
                          : item.type1 === 'BlockQuote'
                          ? 'blockquote'
                          : ''
                      }`}
                      dangerouslySetInnerHTML={{
                        __html: item?.description?.html,
                      }}
                    />
                    {item.author1 && (
                      <PrismicCallout
                        callout={item.author1}
                        position="Right Justify"
                      />
                    )}
                  </div>
                )
              })}
          </div>
        </div>
      </Container>
    </div>
  )
}
