import React, { useMemo } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import Container from '../../container'

export const ProjectContentWithBgImgSection = ({ slice }) => {
  const { primary: primaryData } = useMemo(() => slice, [slice])
  const { width } = useWindowDimensions()

  const imgUrl = (img) => {
    if (width <= 320) {
      return img?.thumbnails?.Mobile?.gatsbyImageData
    } else if (width <= 1024) {
      return img?.thumbnails?.Tablet?.gatsbyImageData
    } else {
      return img?.gatsbyImageData
    }
  }

  return (
    <div className="pt-20 project_content--withbgimg">
      <div
        className="project-t__bg"
        style={{
          backgroundImage: `url(${primaryData.backgroundimage.url})`,
        }}
      >
        <Container>
          <div className="block px-4 xs:px-0 xs:grid w-full items-center grid-rows-1 grid-cols-12 gap-x-2 md:gap-x-5">
            <div
              className={`${
                primaryData.width === 'wide'
                  ? 'col-start-1 col-span-12'
                  : 'col-start-2 col-span-10'
              }`}
            >
              <div className="flex flex-col xs:flex-row justify-start font-serif text-black-900 xs:my-20 xs:-mx-3">
                <div className="px-1 md:px-3 mt-10 xs:mt-0 flex flex-col">
                  <div className="flex flex-1 items-center">
                    <GatsbyImage
                      image={imgUrl(primaryData?.card_image1)}
                      className="w-full object-cover object-center"
                      alt={
                        primaryData?.card_image1?.alt || 'eminent project'
                      }
                    />
                  </div>
                  <div className="description text-black-900 flex flex-1 flex-col relative">
                    <div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: primaryData?.h3?.html,
                        }}
                      />
                      <div
                        dangerouslySetInnerHTML={{
                          __html: primaryData?.content?.html,
                        }}
                        style={{ color: 'black' }}
                      />
                    </div>
                    {primaryData?.service_callout && (
                      <div className="min-w-fit flex items-start justify-end mt-4">
                        <div className="mx-4 tags flex justify-start xs:justify-center items-center">
                          <div className="w-24 border-t border-solid border-gold-900 ml-6 mr-4" />
                          <div className="font-serif text-base text-gold-900 min-w-fit">
                            {primaryData?.service_callout}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}

export const fragments = graphql`
  fragment SliceProjectContentWithBgSection on PrismicProjectTemplateDataBodyContentwithbgimage {
    ... on PrismicProjectTemplateDataBodyContentwithbgimage {
      id
      slice_type
      primary {
        width
        h3 {
          html
        }
        content {
          html
        }
        card_image1 {
          alt
          gatsbyImageData
        }
        backgroundimage {
          alt
          url
        }
        service_callout
      }
    }
  }
`