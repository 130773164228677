import React from 'react'
import Container from '../container'
import Link from '../link'
import FacebookIcon from '../svgIcons/facebook'
import InstagramIcon from '../svgIcons/instagram'
import HouzzIcon from '../svgIcons/houzz'
import CallIcon from '../svgIcons/callIcon'
import LocationPinIcon from '../svgIcons/locationPinIcon'
import DoubleArrowIcon from '../svgIcons/doubleArrowIcon'
import Logo from '../svgIcons/logo'

const menu = [
  {
    name: 'Services',
    link: '/services',
  },
  {
    name: 'Projects',
    link: '/projects',
  },
  {
    name: 'Gallery',
    link: '/gallery',
  },
  {
    name: 'About',
    link: '/about-us',
  },
  {
    name: 'Contact',
    link: '/contact-us',
  },
]

export const Footer = () => (
  <footer className="bg-beige-900 border-black-900">
    <Container>
      <div className="xl:mx-3 pt-14 md:pt-20">
        <div className="footer-top-box flex justify-between items-center border-b border-black-900 pb-7 border-opacity-10">
          <div className="logo-wrapp">
            <Link to="/" ariaLabel="Home">
              <Logo />
            </Link>
          </div>
          <div className="footer-menu-box flex items-center">
            {menu.map((item, index) => (
              <Link
                key={index}
                to={item.link}
                className="flex justify-between items-center mx-2 xl:mx-4 cursor-pointer font-serif text-sm xl:text-sm"
                ariaLabel={item.name}
              >
                <div>{item.name}</div>
                <div>
                  <DoubleArrowIcon className="double-arrow-icon" />
                </div>
              </Link>
            ))}
          </div>
          <div className="footer-top-icons-box flex items-center">
            <Link
              to={`https://www.facebook.com/eminentid`}
              ariaLabel="facebook"
            >
              <FacebookIcon className="mr-4 xl:mr-6 cursor-pointer opacity-20" />
            </Link>
            <Link
              to={`https://www.instagram.com/eminentinteriordesign/`}
              ariaLabel="instagram"
            >
              <InstagramIcon className="mx-4 xl:mx-6 cursor-pointer opacity-20" />
            </Link>
            <Link
              to={`https://www.houzz.com/pro/eminentid/__public`}
              ariaLabel="houzz"
            >
              <HouzzIcon className="mx-4 xl:ml-6 cursor-pointer" />
            </Link>
          </div>
        </div>
        <div className="footer-contact-box block md:flex sm:justify-between sm:items-center py-6 sm:py-8">
          <div className="flex items-center font-serif text-sm justify-center">
            <a href={`tel: 612-767-1242`}>
              <span className="flex">
                <CallIcon className="mr-2" />
                612-767-1242
              </span>
            </a>
          </div>
          <div className="flex items-center font-serif text-sm justify-center my-3 md:my-0">
            <LocationPinIcon className="mr-2" />
            11140 Hwy 55 Suite C, Plymouth, MN 55441
          </div>
          {/* <div className="flex items-center font-serif text-sm justify-center">
            <a href={`mailto: Collaborate@eminentid.com`}>
              <span className="flex">
                <EmailIcon className="mr-2" />
                Collaborate@eminentid.com
              </span>
            </a>
          </div> */}
        </div>
        <div className="social-share-wrapp footer-bottom-icons-box flex items-center">
          <Link to={`https://www.facebook.com/eminentid`} ariaLabel="facebook">
            <FacebookIcon className="mr-4 xl:mr-6 cursor-pointer opacity-20" />
          </Link>
          <Link
            to={`https://www.instagram.com/eminentinteriordesign/`}
            ariaLabel="instagram"
          >
            <InstagramIcon className="mx-4 xl:mx-6 cursor-pointer opacity-20" />
          </Link>
          <Link
            to="https://www.houzz.com/pro/eminentid/__public"
            ariaLabel="houzz"
          >
            <HouzzIcon className="mx-4 xl:ml-6 cursor-pointer" />
          </Link>
        </div>
        <div className="copyright mt-4 xl:mt-10 flex justify-center font-sans text-black-900 text-base xl:text-lg text-center">
          Copyright © {new Date().getFullYear()}
          <br className="block sm:hidden" /> Eminent Interior Design.
          <br className="block sm:hidden" />
          &nbsp; All Rights Reserved.
        </div>
      </div>
    </Container>
  </footer>
)
