import React, { useMemo } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import Container from '../../container'
import Title from '../../title'

const TextCard = ({ head, title, description }) => (
  <div className="text-card">
    {head && (
      <div className="flex items-center mb-5">
        <div className="middle-line" />
        <div className="font-serif text-base text-gold-900">{head}</div>
      </div>
    )}
    <h2 className="font-sans text-[#000] text-center text-x40 md:text-[46px] lg:text-x50 xl:text-x69 leading-tight uppercase mb-5 lg:mb-0">
      <div
        className="title"
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      />
    </h2>

    <div className="details font-serif text-base text-black-900 opacity-80">
      {description}
    </div>
  </div>
)

const ImgCard = ({ image, alt, index }) => (
  <div className={`image-card ${index === 1 ? `w-2/3` : `w-full`}`}>
    <GatsbyImage
      image={image}
      className="services-card-image"
      alt={alt ? alt : 'Services Interior Design'}
    />
  </div>
)

export const ServiceDesignTwoColumn = ({ slice }) => {
  const { primary: primaryData, items: itemsData } = useMemo(
    () => slice,
    [slice],
  )

  return (
    <div className="service-design-twocolumn">
      <Container>
        <div className="mx-0 md:mx-3 flex justify-center sm:justify-start items-start">
          <Title className="services-design-title text-left text-black-900">
            <div
              className="title"
              dangerouslySetInnerHTML={{
                __html: primaryData?.title1?.html,
              }}
            />
          </Title>
        </div>

        <div className="description-wrapp flex justify-center items-center mt-6 xl:mt-10">
          <div className="mx-4 font-serif text-lg text-black-900 text-center sm:text-left description">
            {primaryData?.description?.text}
          </div>
        </div>

        <div className="two-column-wrapp hidden sm:flex mx-0">
          <div className="image-card-wrapp flex-1 xl:ml-3 ml-4 mr-3 md:mr-6 xl:mr-12 flex flex-col items-end">
            {itemsData &&
              itemsData.map((item, index) => (
                <ImgCard
                  image={item?.image?.gatsbyImageData}
                  alt={item?.image?.alt}
                  key={index}
                  index={index}
                />
              ))}
          </div>
          <div className="text-card-wrapp flex-1 ml-3 md:ml-6 mr-4 xl:ml-12 xl:mr-3">
            <TextCard
              title={primaryData?.question?.html}
              description={primaryData?.answer?.text}
            />
            {itemsData &&
              itemsData.map((item, index) => (
                <TextCard
                  head={item?.head?.text}
                  title={item?.title1?.html}
                  description={item?.description?.text}
                  key={index}
                />
              ))}
          </div>
        </div>

        <div className="two-column-wrapp flex flex-col sm:hidden mx-4">
          <TextCard
            title={primaryData?.question?.html}
            description={primaryData?.answer?.text}
          />
          <div className="flex flex-col">
            {itemsData &&
              itemsData.map((item, index) => (
                <div key={index}>
                  <ImgCard
                    image={item?.image?.gatsbyImageData}
                    index={index}
                    alt={item?.image?.alt}
                  />
                  <TextCard
                    head={item?.head?.text}
                    title={item?.title1?.text}
                    description={item?.description?.text}
                  />
                </div>
              ))}
          </div>
        </div>

        {primaryData?.bottom_description?.text && (
          <div className="bottom-description font-serif text-lg text-black-900 text-center">
            {primaryData?.bottom_description?.text}
          </div>
        )}
      </Container>
    </div>
  )
}

export const fragments = graphql`
  fragment SliceServicedesignTwoColumn on PrismicServicedesigntemplateDataBodyDesignServicesTwoColumn {
    ... on PrismicServicedesigntemplateDataBodyDesignServicesTwoColumn {
      id
      slice_type
      primary {
        title1 {
          html
        }
        description {
          text
        }
        background_color
        question {
          html
        }
        answer {
          text
        }
      }
      items {
        title1 {
          html
          text
        }
        image {
          alt
          gatsbyImageData(layout: FULL_WIDTH)
        }
        description {
          text
        }
      }
    }
  }
`