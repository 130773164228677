import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import { PrismicDivider } from '../../prismicDivider'

export const BlogPostDivider = ({ slice }) => {
  const { primary: primaryData } = useMemo(() => slice, [slice])
  return (
    <PrismicDivider
      type={primaryData?.type1}
      position={primaryData?.position}
    />
  )
}

export const fragments = graphql`
  fragment SliceBlogPostDivider on PrismicBlogPostDataBodyDivider {
    ... on PrismicBlogPostDataBodyDivider {
      id
      slice_type
      primary {
        type1
        position
      }
    }
  }
`
