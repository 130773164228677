import { useState, useEffect, useCallback } from 'react'

export default function useWindowDimensions({ ttl = 100 } = {}) {
  const [windowDimensions, setWindowDimensions] = useState({
    width: 0,
    height: 0,
  })

  const updateDimensions = useCallback(() => {
    setWindowDimensions({
      width: window.innerWidth,
      hegiht: window.innerHeight,
    })
  }, [])

  useEffect(() => {
    updateDimensions()
    window.addEventListener('resize', updateDimensions, { passive: true })

    return () => {
      window.removeEventListener('resize', updateDimensions, { passive: true })
    }
  }, [])

  return windowDimensions
}
