import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import { Layout } from '../components/Layout'
import ProjectSlices from '../components/slices/project'
import { ProjectHero } from '../components/sections/project-hero'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { repositoryConfigs } from '../utils/prismicPreviews'

const ProjectTemplate = ({
  data: {
    prismicProjectTemplate: {
      data: {
        body: slices,
        publish_date,
        location,
        project_name,
        type,
        author,
      },
    },
  },
}) => {
  return (
    <Layout>
      <ProjectHero
        publishDate={publish_date}
        location={location}
        projectName={project_name?.text}
        type={type}
        author={author}
      />
      <ProjectSlices slices={slices} />
    </Layout>
  )
}

export const Head = ({ data, location }) => {
  const {
    prismicProjectTemplate: {
      data: { seo_title, seo_keywords, seo_image, seo_description },
    },
  } = data
  const metadata = {
    title: seo_title,
    description: seo_description,
    pathname: location.pathname,
    ogTagImage: seo_image?.url,
    keywords: seo_keywords,
  }
  return <SEO metadata={metadata} />
}

export default withPrismicPreview(ProjectTemplate, repositoryConfigs)

export const ProjectTemplateQuery = graphql`
  query ProjectTemplate($uid: String!) {
    prismicProjectTemplate(uid: { eq: $uid }) {
      id
      uid
      data {
        publish_date
        location
        project_name {
          text
        }
        author {
          slug
          document {
            ... on PrismicMember {
              id
              data {
                body {
                  ... on PrismicMemberDataBodyMember {
                    primary {
                      name {
                        text
                      }
                      role {
                        text
                      }
                      avatar {
                        url
                        thumbnails {
                          Mobile {
                            url
                          }
                          Tablet {
                            url
                          }
                        }
                        alt
                      }
                    }
                  }
                }
              }
            }
          }
        }
        seo_title
        seo_keywords
        type
        seo_image {
          url
        }
        seo_description
        body {
          ...SliceProjectImage
          ...SliceProjectContent
          ...SliceProjectImgCardSection
          ...SliceProjectImgCaptionsSection
          ...SliceProjectContentWithBgSection
          ...SliceProjectNextButton
          ...SliceProjectCallout
          ...SliceProjectDivider
          ...SliceProjectTwoColumn
        }
      }
    }
  }
`
