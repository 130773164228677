import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import { PrismicTwoColumn } from '../../prismicTwoColumn'

export const ProjectTwoColumn = ({ slice }) => {
  const { primary: primaryData, items } = useMemo(() => slice, [slice])
  const { width } = primaryData
  return <PrismicTwoColumn items={items} width={width} />
}

export const fragments = graphql`
  fragment SliceProjectTwoColumn on PrismicProjectTemplateDataBodyTwoColumn {
    ... on PrismicProjectTemplateDataBodyTwoColumn {
      id
      slice_type
      primary {
        width
      }
      items {
        image {
          alt
          gatsbyImageData(imgixParams: { fm: "webp" })
        }
        caption
        headline
        callout
        content {
          html
        }
      }
    }
  }
`
