import React, { useMemo } from 'react'

import Container from '../../container'
import Link from '../../link'
import BackIcon from '../../svgIcons/backIcon'
import DateIcon from '../../svgIcons/dateIcon'
import LocationPinIcon from '../../svgIcons/locationPinIcon'

export const ProjectHero = ({
  publishDate,
  location,
  projectName,
  type,
  author,
}) => {
  return (
    <div className="project-hero text-black-900 py-10">
      <Link
        to="/projects"
        className="back-button text-black-900 text-base md:text-base font-serif flex items-center mb-8 underline"
      >
        <BackIcon className="mr-2 md:mr-4" />
        Back to Projects
      </Link>
      <Container>
        <div className="mx-0 md:mx-3 mb-4 sm:mb-5 md:mb-6 flex flex-col justify-start font-serif uppercase text-center text-gold-900 text-base md:text-base">
          {type}
        </div>

        <div className="mx-0 md:mx-3 mb-4 sm:mb-5 md:mb-6">
          <div className="flex flex-col justify-start">
            <h1 className="title text-center uppercase text-x26 md:text-x50 font-sans">
              {projectName}
            </h1>
          </div>
        </div>

        <div className="flex justify-center items-center font-serif text-black-900 text-base md:text-base opacity-50">
          {publishDate && (
            <div className="flex items-center">
              <DateIcon className="mr-2" />
              {publishDate}
            </div>
          )}
          {location && (
            <div className="ml-7 flex items-center">
              <LocationPinIcon className="mr-2" />
              {location}
            </div>
          )}
        </div>

        {author?.document?.data.body[0]?.primary?.avatar.url && (
          <div className="flex justify-center flex-col items-center mt-8">
            <div className="flex items-center mt-3">
              <img
                src={author?.document?.data.body[0]?.primary?.avatar.url}
                className="member-avatar"
                alt="eminent member"
              />
            </div>
          </div>
        )}

        <div className="flex justify-center flex-col items-center font-serif text-black-900 text-base">
          <div className="flex items-center mt-3 capitalize text-base md:text-lg">
            {author?.document?.data.body[0]?.primary?.name.text}
          </div>
          <div className="flex items-center mt-1 capitalize text-base md:text-base opacity-60">
            {author?.document?.data.body[0]?.primary?.role.text}
          </div>
        </div>
      </Container>
    </div>
  )
}
