import React, { useMemo } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import Slider from 'react-slick'

import LeftCircleArrowIcon from '../../svgIcons/leftCircleArrowIcon'
import RightCircleArrowIcon from '../../svgIcons/rightCircleArrowIcon'
import Container from '../../container'

export const ServiceDesignCarouselSmallCard = ({ slice }) => {
  const { items: data, primary: primaryData } = useMemo(() => slice, [slice])

  const CarouselNextArrow = (props) => {
    const { className, onClick } = props
    return (
      <div
        className={className}
        onClick={onClick}
        onKeyDown={onClick}
        role="button"
        tabIndex={0}
      >
        <RightCircleArrowIcon />
      </div>
    )
  }

  const CarouselPrevArrow = (props) => {
    const { className, onClick } = props
    return (
      <div
        className={className}
        onKeyDown={onClick}
        role="button"
        tabIndex={0}
        onClick={onClick}
      >
        <LeftCircleArrowIcon />
      </div>
    )
  }

  const settings = {
    className: 'serviceDesignCarousel-smallcard',
    dots: false,
    centerMode: false,
    infinite: true,
    speed: 600,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <CarouselNextArrow />,
    prevArrow: <CarouselPrevArrow />,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          dots: false,
          centerMode: false,
          infinite: true,
          speed: 600,
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          dots: false,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          variableWidth: true,
          autoplay: false,
          speed: 600,
        },
      },
    ],
  }

  return (
    <div
      className="carousel-smallcard-section"
      style={{ backgroundColor: '#FAFAFA' }}
    >
      {primaryData?.title1?.html && (
        <div className="mx-0 md:mx-3 flex justify-center items-center mb-8 xl:mb-10">
          <div
            className="title text-x26 md:text-x32 xl:text-x40 text-center text-black-900 uppercase"
            dangerouslySetInnerHTML={{
              __html: primaryData?.title1?.html,
            }}
          />
        </div>
      )}
      <div className="carousel-smallcard-wrapp">
        <Container>
          <div className="ml-3 flex justify-center xl:grid grid-cols-12 gap-x-2 md:gap-x-5">
            <div className="slider-wrapp col-start-2 col-span-10">
              <Slider {...settings}>
                {data.map((item, index) => (
                  <div className="relative px-3" key={index}>
                    <GatsbyImage
                      image={item?.image?.gatsbyImageData}
                      className="w-full object-cover object-center"
                      alt={item?.image?.alt ? item?.image?.alt : 'eminent'}
                    />
                    <div className="text-lg md:text-x22 xl:text-x26 text-center font-sans uppercase mt-6 mb-2">
                      {item?.title1?.text}
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}

export const fragments = graphql`
  fragment SliceServiceDesignSmallCardCarousel on PrismicServicedesigntemplateDataBodyCarouselSmallcard {
    ... on PrismicServicedesigntemplateDataBodyCarouselSmallcard {
      id
      slice_type
      primary {
        title1 {
          html
        }
      }
      items {
        title1 {
          text
        }
        image {
          alt
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
`