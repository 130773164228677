import React, { useMemo } from 'react'
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from 'gatsby'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import Container from '../../container'

const TextCard = ({ head, title, titleText, description, className }) => (
  <div className={`${className} text-card flex-1 md:mx-3 mt-7 xs:mt-0`}>
    {head && (
      <div className="flex items-center mb-3 sm:mb-4 md:mb-5">
        <div className="middle-line" />
        <div className="font-serif text-base md:text-base text-gold-900">
          {head}
        </div>
      </div>
    )}
    <div className="font-sans text-[#000] text-center text-x40 md:text-[46px] lg:text-x50 xl:text-x69 leading-tight uppercase mb-5 lg:mb-0">
      <div
        className="title hidden sm:block"
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <div className="title block sm:hidden">{titleText}</div>
    </div>
    <div className="details font-serif text-base md:text-base text-black-900 opacity-80">
      {description}
    </div>
  </div>
)

const ImgCard = ({ image, className, alt }) => (
  <div className={`${className} flex-1 md:mx-3 w-full`}>
    {image && (
      <GatsbyImage
        image={image}
        className="services-card-image"
        alt={alt ? alt : 'Services Interior Design'}
      />
    )}
  </div>
)

export const ServiceDesignServices = ({ slice }) => {
  const { primary: primaryData, items: itemsData } = useMemo(
    () => slice,
    [slice]
  )

  const { width } = useWindowDimensions()

  const imgUrl = (image) => {
    if (width <= 320) {
      return image?.thumbnails?.Mobile?.gatsbyImageData
    } else if (width <= 834) {
      return image?.thumbnails?.Tablet?.gatsbyImageData
    } else {
      return image?.gatsbyImageData
    }
  }

  return (
    <div className="service-design-services mx-4">
      <Container>
        <div className="mx-0 md:mx-3 flex justify-center items-center">
          <div className="services-design-title text-x32 md:text-x60 lg:text-x80 2xl:text-x90 font-sans uppercase text-center text-black-900">
            <div
              className="title"
              dangerouslySetInnerHTML={{
                __html: primaryData?.title1?.html,
              }}
            />
          </div>
        </div>
        <div className="description-wrapp flex justify-center items-center mt-5 sm:mt-6 md:mt-10">
          <div className="font-serif text-base md:text-lg text-black-900 text-center description">
            {primaryData?.top_description?.text}
          </div>
        </div>
        <div className="-mx-2">
          {itemsData.map((item, index) => (
            <div
              className={`two-column-wrapp mx-0 flex flex-col sm:flex-row items-start sm:items-center ${
                index % 2 === 0 ? 'sm:flex-row-reverse' : ''
              }`}
              key={index}
            >
              <ImgCard
                image={imgUrl(item?.image)}
                className="mx-0 px-2 sm:mx-2 dm:px-0"
                alt={item?.image?.alt}
              />
              <TextCard
                head={item?.head?.text}
                title={item?.title1?.html}
                titleText={item?.title1?.text}
                description={item?.description?.text}
                className="mx-0 px-2 sm:mx-2 dm:px-0 pt-7 sm:pt-0"
              />
            </div>
          ))}
        </div>
        {primaryData?.bottom_description?.text && (
          <div className="bottom-description font-serif text-base md:text-lg text-black-900 text-center">
            {primaryData?.bottom_description?.text}
          </div>
        )}
      </Container>
    </div>
  )
}

export const fragments = graphql`
  fragment SliceServicedesignServices on PrismicServicedesigntemplateDataBodyDesignServices {
    ... on PrismicServicedesigntemplateDataBodyDesignServices {
      id
      slice_type
      primary {
        title1 {
          html
        }
        top_description {
          text
        }
        bottom_description {
          text
        }
        backgroundcolor
      }
      items {
        title1 {
          html
          text
        }
        image {
          thumbnails {
            Mobile {
              gatsbyImageData(layout: FULL_WIDTH)
            }
            Tablet {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          alt
          gatsbyImageData(layout: FULL_WIDTH)
        }
        head {
          text
        }
        description {
          text
        }
      }
    }
  }
`