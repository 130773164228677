import React from 'react'
import PropTypes from 'prop-types'
import config from '../config'
import favicon from '../images/favicon.ico'
import appleIcon57x57 from '../images/apple-icon-57x57.png'
import appleIcon60x60 from '../images/apple-icon-60x60.png'
import appleIcon72x72 from '../images/apple-icon-72x72.png'
import appleIcon76x76 from '../images/apple-icon-76x76.png'
import appleIcon114x114 from '../images/apple-icon-114x114.png'
import appleIcon120x120 from '../images/apple-icon-120x120.png'
import appleIcon144x144 from '../images/apple-icon-144x144.png'
import appleIcon152x152 from '../images/apple-icon-152x152.png'
import appleIcon180x180 from '../images/apple-icon-180x180.png'
import androidIcon192x192 from '../images/android-icon-192x192.png'
import favicon32x32 from '../images/favicon-32x32.png'
import favicon96x96 from '../images/favicon-96x96.png'
import favicon16x16 from '../images/favicon-16x16.png'
import msIcon144x144 from '../images/ms-icon-144x144.png'

export const logoSchemaJson = ({ fullPageUrl }) => ({
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'Eminent',
  alternateName: 'Eminent Interior Design',
  url: fullPageUrl,
  logo: favicon96x96,
  contactPoint: {
    '@type': 'ContactPoint',
    telephone: '+1-612-767-1242',
    contactType: 'Customer Support',
    contactOption: 'TollFree',
    areaServed: ['US'],
    availableLanguage: ['en'],
  },
  sameAs: [
    'https://www.facebook.com/eminentid',
    'https://www.instagram.com/eminentinteriordesign/',
    'https://www.eminentinteriordesign.com',
  ],
})

const SEO = ({ metadata, children }) => {
  const fullPageUrl = `${config.siteUrl}${metadata.pathname}`
  const ogTagImage = metadata.ogTagImage
  const schemaJson = logoSchemaJson({
    fullPageUrl,
    ogTagImage,
  })

  return (
    <>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title itemProp="name" lang="en">
        {metadata.title}
      </title>
      <link rel="shortcut icon" href={favicon} />
      <link rel="canonical" href={fullPageUrl} />

      <meta name="description" content={metadata.description} />
      <meta name="keywords" content={metadata.keywords} />
      <meta property="og:title" content={metadata.title} />
      <meta property="og:description" content={metadata.description} />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content={`${config.siteUrl}${metadata.pathname}`}
      />
      <meta property="og:site_name" content={metadata.title} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:locale" content={config.siteLanguage} />
      <meta itemProp="name" content={metadata.title} />
      <meta property="og:image" content={metadata.ogTagImage} />
      <meta itemProp="description" content={metadata.description} />
      <link rel="apple-touch-icon" sizes="57x57" href={appleIcon57x57} />
      <link rel="apple-touch-icon" sizes="60x60" href={appleIcon60x60} />
      <link rel="apple-touch-icon" sizes="72x72" href={appleIcon72x72} />
      <link rel="apple-touch-icon" sizes="76x76" href={appleIcon76x76} />
      <link rel="apple-touch-icon" sizes="114x114" href={appleIcon114x114} />
      <link rel="apple-touch-icon" sizes="120x120" href={appleIcon120x120} />
      <link rel="apple-touch-icon" sizes="144x144" href={appleIcon144x144} />
      <link rel="apple-touch-icon" sizes="152x152" href={appleIcon152x152} />
      <link rel="apple-touch-icon" sizes="180x180" href={appleIcon180x180} />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href={androidIcon192x192}
      />
      <link rel="icon" type="image/png" sizes="32x32" href={favicon32x32} />
      <link rel="icon" type="image/png" sizes="96x96" href={favicon96x96} />
      <link rel="icon" type="image/png" sizes="16x16" href={favicon16x16} />
      <meta name="msapplication-TileImage" content={msIcon144x144} />
      <script type="application/ld+json">{JSON.stringify(schemaJson)}</script>
      {children}
    </>
  )
}

SEO.propTypes = {
  metadata: PropTypes.object.isRequired,
}

export default SEO
