import * as React from 'react'
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews'
import { linkResolver } from './src/utils/linkResolver'
import BlogTemplate from './src/templates/blog-template'
import MemberTemplate from './src/templates/member'
import ProjectTemplate from './src/templates/project'
import ServiceTemplate from './src/templates/service'


import './src/stylesheets/main.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME || 'eminentid',
        linkResolver,
        componentResolver: (type) => {
          if (type === 'blog_post') return BlogTemplate;
          if (type === 'project_template') return ProjectTemplate;
          if (type === 'member') return MemberTemplate;
          if (type === 'servicedesigntemplate') return ServiceTemplate;
        }
        // componentResolverFromMap({
        //   blog_post: BlogTemplate,
        //   project_template: ProjectTemplate,
        //   member: MemberTemplate,
        //   servicedesigntemplate: ServiceTemplate,
        // }),
      },
    ]}
  >
    {element}
  </PrismicPreviewProvider>
)
