import React, { useMemo } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import Container from '../../container'
import useWindowDimensions from '../../../hooks/useWindowDimensions'

export const MemberHero = ({ slice }) => {
  const { primary: data } = useMemo(() => slice, [slice])
  const { width } = useWindowDimensions()

  const imgUrl = (img) => {
    if (!img) return null
    if (width <= 834 && width >= 320) {
      return img?.thumbnails?.Tablet?.gatsbyImageData
    } else if (width < 320) {
      return img?.thumbnails?.Mobile?.gatsbyImageData
    } else {
      return img?.gatsbyImageData
    }
  }

  return (
    <>
      <div
        className="member-hero"
        style={{ backgroundColor: data?.backgroundcolor }}
      >
        <div className="member-hero-container">
          <div className="member-hero-top-wrapp mt-16 mb-20 flex-1 flex md:hidden flex-col items-center justify-center">
            <div className="member-hero-top-name text-center font-sans uppercase">
              <div
                className="title text-black-900 text-x40 md:text-x50 xl:text-x80 2xl:text-x90"
                dangerouslySetInnerHTML={{
                  __html: data?.name?.text,
                }}
              />
            </div>

            <div className="flex justify-center items-center">
              <div className="haigrast-text font-mono text-gold-900 text-x40 md:text-x50 2xl:text-x80 text-center">
                About
              </div>
            </div>
          </div>
          <div className="flex flex-col-reverse xs:flex-row">
            <div className="member-callout-wrapp flex flex-col justify-center flex-1">
              <div className="member-callout flex flex-col justify-center mt-10 xs:mt-0">
                <div className="title text-lg sm:text-x22 lg:text-x26 text-black-900 font-sans uppercase mt-0 mb-2">
                  {data?.name?.text}
                </div>
                <div className="flex items-center mb-6 xs:mb-10">
                  <div className="middle-line" />
                  <div className=" font-serif text-base xl:text-base text-gold-900">
                    {data?.role?.text}
                  </div>
                </div>

                <div
                  className="description font-serif text-base md:text-base text-black-900 opacity-80"
                  dangerouslySetInnerHTML={{
                    __html: data?.callout?.html,
                  }}
                />
              </div>
            </div>

            <div className="hidden flex-1 md:flex flex-col items-center justify-center">
              <div className="member-hero-name text-center font-sans uppercase">
                <h1
                  className="title text-black-900 md:text-x50 xl:text-x80 2xl:text-x90"
                  dangerouslySetInnerHTML={{
                    __html: data?.name?.text,
                  }}
                />
              </div>

              <div className="flex justify-center items-center">
                <div className="haigrast-text font-mono text-gold-900 text-x40 md:text-x50 2xl:text-x80 text-center">
                  About
                </div>
              </div>
            </div>

            <div className="hero-photo-wrapp flex-1">
              <GatsbyImage
                image={imgUrl(data?.hero_photo)}
                className="w-full object-cover object-center"
                alt={data?.hero_photo?.alt || 'eminent services design'}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="member-hero-bottom">
        <Container>
          <div className="mx-0 md:mx-3 flex flex-col xs:flex-row lg:grid grid-rows-1 grid-cols-12 gap-x-2 md:gap-x-5">
            <div className="flex flex-col w-full xs:flex-row col-start-2 col-span-10 row-start-1">
              <div className="flex-1 mr-0 xs:mr-4 md:mr-8 lg:mr-12">
                <GatsbyImage
                  image={imgUrl(data?.section_photo)}
                  className="w-full object-cover object-center"
                  alt={data?.section_photo?.alt || 'eminent services design'}
                />
              </div>
              <div className="right-text-wrapp flex-1 ml-0 xs:ml-4 md:ml-8 lg:ml-12">
                <div className="flex items-center mt-10 xs:mt-0 mb-6 md:mb-10">
                  <div className="middle-line" />
                  <div className="font-serif text-base md:text-base text-gold-900">
                    {data?.bio?.html && 'Bio'}{' '}
                    {data?.awards?.html && '& Awards'}
                  </div>
                </div>
                <div
                  className="font-serif text-base md:text-base text-black-900 opacity-80"
                  dangerouslySetInnerHTML={{
                    __html: data?.bio?.html,
                  }}
                />

                <div className="text-lg sm:text-x22 md:text-x26 text-black-900 font-sans uppercase mt-1 mb-2">
                  {data?.awards?.html && 'AWARDS'}
                </div>

                <div
                  className="font-serif text-base md:text-base text-black-900 opacity-80"
                  dangerouslySetInnerHTML={{
                    __html: data?.awards?.html,
                  }}
                />

                <div className="text-lg sm:text-x22 md:text-x26 text-black-900 font-sans uppercase mt-8 mb-2">
                  {data?.volunteerism?.html && 'Volunteerism'}
                </div>

                <div
                  className="font-serif text-base md:text-base text-black-900 opacity-80"
                  dangerouslySetInnerHTML={{
                    __html: data?.volunteerism?.html,
                  }}
                />
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  )
}

export const fragments = graphql`
  fragment SliceMember on PrismicMemberDataBodyMember {
    ... on PrismicMemberDataBodyMember {
      id
      slice_type
      primary {
        awards {
          html
        }
        bio {
          html
        }
        callout {
          html
        }
        hero_photo {
          thumbnails {
            Mobile {
              gatsbyImageData(imgixParams: { fm: "webp" })
            }
            Tablet {
              gatsbyImageData(imgixParams: { fm: "webp" })
            }
          }
          alt
          gatsbyImageData(imgixParams: { fm: "webp" })
        }
        name {
          text
        }
        role {
          text
        }
        section_photo {
          thumbnails {
            Mobile {
              gatsbyImageData(imgixParams: { fm: "webp" })
            }
            Tablet {
              gatsbyImageData(imgixParams: { fm: "webp" })
            }
          }
          alt
          gatsbyImageData(imgixParams: { fm: "webp" })
        }
        volunteerism {
          html
        }
      }
    }
  }
`