import React from 'react'

const RightCircleArrowIcon = () => {
  return (
    <div className="right-circle-arrow-icon">
      <svg
        width="10"
        height="16"
        viewBox="0 0 10 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.13569 0.46967C1.42858 0.176777 1.90345 0.176777 2.19635 0.46967L9.19635 7.46967C9.337 7.61032 9.41602 7.80109 9.41602 8C9.41602 8.19891 9.337 8.38968 9.19635 8.53033L2.19635 15.5303C1.90345 15.8232 1.42858 15.8232 1.13569 15.5303C0.842792 15.2374 0.842792 14.7626 1.13569 14.4697L7.60536 8L1.13569 1.53033C0.842792 1.23744 0.842792 0.762563 1.13569 0.46967Z"
          fill="#34342E"
        />
      </svg>
    </div>
  )
}

export default RightCircleArrowIcon
