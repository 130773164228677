import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image"
import Container from '../container'
import Caption from '../caption'

export const PrismicImage = ({ type, caption, image }) => {
  return (
    <div className="text-black-900 py-10">
      <Container>
        <div className="grid grid-cols-12 md:gap-x-5 font-serif text-black-900 mx-4">
          <div
            className={`col-start-1 col-span-12 ${
              type === 'Middle' && 'md:col-start-2 md:col-span-10'
            } flex flex-col`}
          >
            <div className="flex items-center">
              {image && (
                <GatsbyImage
                  image={image.gatsbyImageData}
                  className="w-full object-cover object-center"
                  alt={image?.alt || `eminent blog`}
                />
              )}
            </div>
            {caption && <Caption>{caption}</Caption>}
          </div>
        </div>
      </Container>
    </div>
  )
}
