/**
 * The Link Resolver used for the Prismic repository. This function converts a
 * Prismic document to a URL within your app. It is used throughout your app to
 * resolve document links and support editor previews.
 *
 * {@link https://prismic.io/docs/technologies/link-resolver-gatsby}
 *
 * @param doc Prismic document to resolve to a URL within your app.
 *
 * @returns URL for the provided Prismic document.
 */
exports.linkResolver = (doc) => {
  switch (doc.type) {
    case 'page': {
      return `/${doc.uid}`
    }

    case 'homepage': {
      return `/`
    }

    case 'blog': {
      return `/blog`
    }

    case 'about_u': {
      return `/about-us`
    }

    case 'our_process': {
      return '/our-process'
    }

    case 'team': {
      return '/team'
    }

    case 'gallery_page': {
      return `/gallery`
    }

    case 'contactuspage': {
      return `/contact-us`
    }

    case 'blog_post': {
      return `/blog/${doc.uid}`
    }

    case 'member': {
      return `/teams/${doc.uid}`
    }

    case 'project_template': {
      return `/projects/${doc.uid}`
    }

    case 'servicedesigntemplate': {
      return `/services/${doc.uid}`
    }

    default:
      return '/'
  }
}
