import React from 'react'
import { Link as GatsbyLink } from 'gatsby'

const Link = ({
  children,
  to,
  className,
  activeClassName,
  ariaLabel,
  tabIndex = '0',
}) => {
  // If `to` is not passed, return a span
  if (!to) return <span className={className}>{children}</span>

  // If URL starts with `/` then use Gatsby Link
  const internal = /^\/(?!\/)/.test(to)
  if (internal)
    return (
      <GatsbyLink
        to={to}
        className={className}
        activeClassName={activeClassName}
        aria-label={ariaLabel}
        tabIndex={tabIndex}
      >
        {children}
      </GatsbyLink>
    )

  // Otherwise return a normal link
  return (
    <a
      href={to}
      className={className}
      target="_blank"
      rel="noreferrer"
      aria-label={ariaLabel}
      tabIndex={tabIndex}
    >
      {children}
    </a>
  )
}

export default Link
