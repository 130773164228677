import React, { useState, useEffect } from 'react';
import { Header } from '../Header';
import { Hamburger } from '../Header/hamburger';
import { Footer } from '../Footer';

export const Layout = ({ children }) => {
  const [navOpen, setNavOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    handleResize(); // Initialize the state on mount
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="wrapper-outer">
      <Header setNavOpen={setNavOpen} navOpen={navOpen} isMobile={isMobile} />
      {isMobile && (
        <div className={`fixed top-0 z-50 w-full transform transition-transform duration-300 ease-out ${navOpen ? 'translate-x-0' : 'translate-x-full'}`}>
          <Hamburger setNavOpen={setNavOpen} navOpen={navOpen} />
        </div>
      )}
      <main className="min-h-screen">{children}</main>
      <Footer />
    </div>
  );
};
