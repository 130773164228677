import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import { PrismicImage } from '../../prismicImage'

export const fragments = graphql`
  fragment BlogPostImages on PrismicBlogPostDataBodyImage {
    ... on PrismicBlogPostDataBodyImage {
      id
      slice_type
      primary {
        width
        image {
          alt
          gatsbyImageData(layout: FULL_WIDTH)
        }
        caption
      }
    }
  }
`

export const BlogPostImage = ({ slice }) => {
  const { primary: primaryData } = useMemo(() => slice, [slice])
  const { width, image, caption } = primaryData
  return <PrismicImage type={width} image={image} caption={caption} />
}
