import React, { useMemo } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import Link from '../../link'

import { graphql } from 'gatsby'
import Slider from 'react-slick'
import LeftCircleArrowIcon from '../../svgIcons/leftCircleArrowIcon'
import RightCircleArrowIcon from '../../svgIcons/rightCircleArrowIcon'
import LocationPinIcon from '../../svgIcons/locationPinIcon'
import useScreenWidth from '../../../hooks/useScreenWidth'

export const SpotlightsSlider = ({ slice }) => {
  const { items: data, primary: primaryData } = useMemo(() => slice, [slice])
  const screenWidth = useScreenWidth()
  const CarouselNextArrow = (props) => {
    const { className, onClick } = props
    return (
      <div
        className={className}
        onClick={onClick}
        onKeyDown={onClick}
        role="button"
        tabIndex={0}
      >
        <RightCircleArrowIcon />
      </div>
    )
  }

  const CarouselPrevArrow = (props) => {
    const { className, onClick } = props
    return (
      <div
        className={className}
        onKeyDown={onClick}
        role="button"
        tabIndex={0}
        onClick={onClick}
      >
        <LeftCircleArrowIcon />
      </div>
    )
  }

  const settings = {
    className: 'services-spotlights-slider',
    accessibility: false,
    dots: false,
    centerMode: true,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    autoplay: false,
    nextArrow: <CarouselNextArrow />,
    prevArrow: <CarouselPrevArrow />,
    arrows: false,
  }

  return (
    <div
      className="spotlights-slider-section pt-12 md:pt-20"
      style={{ backgroundColor: primaryData?.backgroundcolor }}
    >
      {(primaryData?.title1?.text || primaryData?.title?.text) && (
        <div className="max-w-full flex flex-col items-center justify-center mb-16">
          <div className="flex flex-col items-center justify-center 2xl:mb-[48px] mb-[30px]"></div>
          <h2 className="font-sans text-[#000] text-x40 md:text-[46px] lg:text-x50 xl:text-x69 leading-tight uppercase">
            {primaryData?.title1?.text || primaryData?.title?.text}
          </h2>
        </div>
      )}
      <div className="spotlights-slider-wrapp">
        {screenWidth > 640 && (
          <div className="ml-0">
            <Slider {...settings}>
              {data.concat(data).map((item, index) => {
                const imageItems =
                  item?.project.document?.data.card_image.gatsbyImageData
                const imageAlt = item?.project.document?.data.card_image.alt
                const location = item?.project.document?.data.location
                const projectName = item?.project.document?.data.project_name.text
                const link = item?.project.document?.uid
                if (imageItems) {
                  return (
                    <Link to={`/projects/${link}`} tabIndex="-1">
                      <div className="project-card relative" key={index}>
                        <div className="rounded-md border-0 w-full h-64 overflow-hidden flex-shrink-0">
                          <GatsbyImage
                            image={imageItems}
                            className="slider-image w-full h-full transistion-all duration-500 ease-in-out transform bg-center bg-cover object-center object-cover hover:scale-110 w-full h-full transistion-all duration-500 ease-in-out transform bg-center bg-cover object-center object-cover hover:scale-110"
                            srcSet={imageItems.srcSet}
                            placeholder="blurred"
                            loading='lazy'
                            alt={imageAlt ? imageAlt : 'eminent featured project'}
                          />
                        </div>
                        <h3 className="text-lg lg:text-2xl text-black-900 uppercase mt-6 mb-2">
                          {projectName}
                        </h3>
                        <div className="flex justify-center sm:justify-start items-center">
                          <p className="text-[18px] lg:text-xl text-black-500">
                            {location}
                          </p>
                        </div>
                      </div>
                    </Link>
                  )
                }
              })}
            </Slider>
          </div>
        )}
        {screenWidth <= 640 && (
          <div className="ml-1 flex flex-col justify-center items-center gap-4">
            {data.concat(data).map((item, index) => {
              const imageItems =
                item?.project.document?.data.card_image.gatsbyImageData
              const imageAlt = item?.project.document?.data.card_image.alt
              const location = item?.project.document?.data.location
              const projectName = item?.project.document?.data.project_name.text
              const link = item?.project.document?.uid
              if (imageItems) {
                return (
                  <div className="project-card relative" key={index}>
                    <GatsbyImage
                      image={imageItems}
                      className="slider-image"
                      srcSet={imageItems.srcSet}
                      placeholder="blurred"
                      loading='lazy'
                      alt={imageAlt ? imageAlt : 'eminent featured project'}
                    />

                    <Link to={`/projects/${link}`} tabIndex="-1">
                      <h3 className="text-center sm:text-left text-lg sm:text-x22 md:text-x26 font-sans uppercase mt-4 sm:mt-5 md:mt-6 md:mb-2 underline">
                        {projectName}
                      </h3>
                    </Link>
                    <div className="flex justify-center sm:justify-start items-center">
                      <LocationPinIcon />
                      <div className="text-center sm:text-left text-base md:text-base font-serif leading-relaxed ml-1 md:ml-2.5">
                        {location}
                      </div>
                    </div>
                  </div>
                )
              }
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export const fragments = graphql`
  fragment SliceServicesCarousel on PrismicServicesDataBodyServicesCarousel {
    ... on PrismicServicesDataBodyServicesCarousel {
      id
      slice_type
      primary {
        backgroundcolor
        title1 {
          text
        }
      }
      items {
        project {
          document {
            ... on PrismicProjectTemplate {
              data {
                type
                location
                project_name {
                  text
                }
                card_image {
                  alt
                  gatsbyImageData(
                    placeholder: BLURRED
                    imgixParams: {fm: "avif", w: 1200}
                  )
                }
              }
              uid
              url
            }
          }
        }
      }
    }
  }
  fragment SliceMemberCarousel on PrismicMemberDataBodyMemberSlider {
    ... on PrismicMemberDataBodyMemberSlider {
      id
      slice_type
      primary {
        backgroundcolor
        title {
          text
        }
      }
      items {
        project {
          document {
            ... on PrismicProjectTemplate {
              data {
                type
                location
                project_name {
                  text
                }
                card_image {
                  alt
                  gatsbyImageData(
                    placeholder: BLURRED
                    imgixParams: {fm: "avif", w: 1200}
                  )
                }
              }
              uid
              url
            }
          }
        }
      }
    }
  }

  fragment SliceBlogPostProjectsCarousel on PrismicBlogPostDataBodyServicesCarousel {
    ... on PrismicBlogPostDataBodyServicesCarousel {
      id
      slice_type
      items {
        title1 {
          text
        }
        location {
          text
        }
        image {
          alt
          gatsbyImageData(
            placeholder: BLURRED
            imgixParams: {fm: "avif", w: 1200}
          )
        }
      }
    }
  }
`
