import React from 'react'
import Container from '../container'

export const PrismicDivider = ({ type, position }) => {
  return (
    <Container>
      <div className="grid grid-cols-12 md:gap-x-5 mx-4">
        <div className="col-start-1 col-span-12 md:col-start-2 md:col-span-10">
          <div
            className={`flex items-center ${
              position === 'Center'
                ? 'justify-center'
                : position === 'Right Justify' && 'justify-end'
            }`}
          >
            <div
              className={`${
                type === 'Best Fit' ? 'w-[70%]' : 'w-full'
              } border-solid border-t border-black-800 my-1 `}
            />
          </div>
        </div>
      </div>
    </Container>
  )
}
