import React, { useMemo } from 'react'
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from 'gatsby'
import Slider from 'react-slick'
import Container from '../../container'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { chunkArray } from '../../../utils'

export const fragments = graphql`
  fragment BlogPostImageGroup on PrismicBlogPostDataBodyImageGroup {
    ... on PrismicBlogPostDataBodyImageGroup {
      id
      slice_type
      items {
        description {
          text
        }
        image {
          thumbnails {
            Mobile {
              gatsbyImageData(layout: FULL_WIDTH)
            }
            Tablet {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          alt
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
`

export const BlogPostImageGroup = ({ slice }) => {
  const { items: itemsData } = useMemo(() => slice, [slice])
  const { width } = useWindowDimensions()

  const imgUrl = (img) => {
    if (width <= 320) {
      return img?.thumbnails?.Mobile?.gatsbyImageData
    } else if (width <= 834) {
      return img?.thumbnails?.Tablet?.gatsbyImageData
    } else {
      return img?.gatsbyImageData
    }
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1.03,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
  }

  return (
    <div className="blog-post-image-group text-black-900">
      <Container className="hidden xs:block">
        {chunkArray(itemsData, 2).map((section, sectionIndex) => (
          <div className="two-column flex flex-wrap" key={sectionIndex}>
            {section.map((item, itemIndex) => (
              <div
                className={`flex justify-start flex-col font-serif text-black-900 ${
                  itemIndex === 0 ? 'mr-2 ml-4' : 'ml-2 mr-4'
                } flex-1 image-card`}
                key={itemIndex}
              >
                {item?.image?.gatsbyImageData && (
                  <div className="flex items-center">
                    <GatsbyImage
                      image={imgUrl(item?.image)}
                      className="w-full object-cover object-center"
                      alt={item?.image?.alt || 'eminent blog'}
                    />
                  </div>
                )}
                {item?.description?.text && (
                  <div className="flex justify-center items-center mt-3 text-base md:text-base text-center font-sans text-black-900 opacity-50">
                    {item?.description?.text}
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}
      </Container>
      <div className="block xs:hidden pl-4">
        <Slider {...settings}>
          {itemsData.map((item, index) => (
            <div
              className="slider-card flex justify-start flex-col font-serif text-black-900 pr-4"
              key={index}
            >
              {item?.image?.gatsbyImageData && (
                <div className="flex items-center">
                  <GatsbyImage
                    image={imgUrl(item?.image)}
                    className="w-full object-cover object-center"
                    alt={item?.image?.alt || 'eminent blog'}
                  />
                </div>
              )}
              {item?.description?.text && (
                <div className="flex justify-center items-center mt-3 text-base md:text-base text-center font-sans text-black-900 opacity-50">
                  {item?.description?.text}
                </div>
              )}
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}