import React from 'react'

const LocationPinIcon = ({
  className,
  width = '20',
  height = '20',
  opacity = '1',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        opacity={opacity}
        d="M4.08398 8.45617C4.08398 6.39153 4.83717 4.90049 5.92068 3.91953C7.01696 2.92701 8.49755 2.41601 10.0006 2.41602C11.5037 2.41602 12.9843 2.92703 14.0806 3.91955C15.1641 4.90051 15.9173 6.39155 15.9173 8.45617C15.9173 10.8398 14.6435 12.9692 13.1725 14.6234C12.5059 15.373 12.0904 15.8323 11.6416 16.1299C11.2397 16.3965 10.7806 16.5493 10.0008 16.5494C9.22102 16.5494 8.7619 16.3965 8.35999 16.13C7.91124 15.8324 7.49571 15.3731 6.82903 14.6235C5.35792 12.9693 4.08398 10.8399 4.08398 8.45617ZM17.4173 8.45617C17.4173 5.99403 16.5038 4.09 15.0873 2.80757C13.6836 1.53671 11.8309 0.91602 10.0007 0.916016C8.17042 0.916011 6.31767 1.53669 4.91395 2.80756C3.49746 4.08998 2.58398 5.99401 2.58398 8.45617C2.58398 11.3733 4.13189 13.8479 5.70817 15.6203L5.75579 15.6739C6.36076 16.3543 6.90171 16.9627 7.53095 17.3801C8.22347 17.8393 8.98496 18.0494 10.0009 18.0494C11.0167 18.0493 11.7782 17.8393 12.4707 17.38C13.0999 16.9626 13.6409 16.3542 14.2458 15.6737L14.2934 15.6202C15.8696 13.8478 17.4173 11.3732 17.4173 8.45617ZM8.25065 8.33271C8.25065 7.36621 9.03415 6.58271 10.0007 6.58271C10.9671 6.58271 11.7507 7.36621 11.7507 8.33271C11.7507 9.29921 10.9671 10.0827 10.0007 10.0827C9.03415 10.0827 8.25065 9.29921 8.25065 8.33271ZM10.0007 5.08271C8.20573 5.08271 6.75065 6.53779 6.75065 8.33271C6.75065 10.1276 8.20573 11.5827 10.0007 11.5827C11.7956 11.5827 13.2507 10.1276 13.2507 8.33271C13.2507 6.53779 11.7956 5.08271 10.0007 5.08271Z"
        fill="#34342E"
      />
    </svg>
  )
}

export default LocationPinIcon
