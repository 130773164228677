import React from 'react'

const HouzzIcon = ({ className, width = '16', height = '16' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M9.78799 16H15.1673V6.91133L4.39999 3.83067V0H0.833984V16H6.21332V10.9627H9.78799V16Z"
          fill="#34342E"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default HouzzIcon
