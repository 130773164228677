import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import { Layout } from '../components/Layout'
import ServiceDesignSlices from '../components/slices/service-design'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { repositoryConfigs } from '../utils/prismicPreviews'

const ServiceTemplate = ({
  data: {
    prismicServicedesigntemplate: {
      data: { body: slices },
    },
  },
}) => {
  return (
    <Layout>
      <ServiceDesignSlices slices={slices} />
    </Layout>
  )
}

export const Head = ({ data, location }) => {
  const {
    prismicServicedesigntemplate: {
      data: { seo_title, seo_keywords, seo_image, seo_description },
    },
  } = data
  const metadata = {
    title: seo_title,
    description: seo_description,
    pathname: location.pathname,
    ogTagImage: seo_image?.url,
    keywords: seo_keywords,
  }
  return <SEO metadata={metadata} />
}

export default withPrismicPreview(ServiceTemplate, repositoryConfigs)

export const ServiceDesignTemplateQuery = graphql`
  query ServiceDesignTemplate($uid: String!) {
    prismicServicedesigntemplate(uid: { eq: $uid }) {
      id
      uid
      type
      data {
        seo_title
        seo_keywords
        seo_image {
          url
        }
        seo_description
        body {
          ...SliceServicedesignHero
          ...SliceServicedesignServices
          ...SliceContactus
          ...SliceServicedesignGallery
          ...SliceServicedesignTextbox
          ...SliceServiceDesignSmallCardCarousel
          ...SliceServicedesignTwoColumn
        }
      }
    }
  }
`
