import React from 'react'

const LeftCircleArrowIcon = () => {
  return (
    <div className="left-circle-arrow-icon">
      <svg
        width="10"
        height="16"
        viewBox="0 0 10 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.86431 0.46967C8.57142 0.176777 8.09655 0.176777 7.80365 0.46967L0.803654 7.46967C0.663001 7.61032 0.583983 7.80109 0.583983 8C0.583983 8.19891 0.663001 8.38968 0.803654 8.53033L7.80365 15.5303C8.09655 15.8232 8.57142 15.8232 8.86431 15.5303C9.15721 15.2374 9.15721 14.7626 8.86431 14.4697L2.39464 8L8.86431 1.53033C9.15721 1.23744 9.15721 0.762563 8.86431 0.46967Z"
          fill="#34342E"
        />
      </svg>
    </div>
  )
}

export default LeftCircleArrowIcon
