import React from 'react'

const CloseIcon = ({
  className,
  width = '36',
  height = '36',
  onClick,
  color = 'white',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <rect x="0.5" y="0.5" width="35" height="35" stroke={color} />
      <path
        d="M12 24L24 12M12 12L24 24"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CloseIcon
