import React from 'react'

const Caption = ({ children }) => {
  return (
    <figcaption className="font-serif flex justify-center items-center mt-1 text-base leading-4 text-center text-black-900 opacity-90">
      {children}
    </figcaption>
  )
}

export default Caption
