import React, { useMemo } from 'react'
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from 'gatsby'
import Slider from 'react-slick'
import useWindowDimensions from '../../../hooks/useWindowDimensions'

export const BlogImageCarousel = ({ slice }) => {
  const { items: data } = useMemo(() => slice, [slice])

  const { width } = useWindowDimensions()

  const imgUrl = (img) => {
    if (width <= 425) {
      return img?.thumbnails?.Mobile?.gatsbyImageData
    } else if (width <= 834) {
      return img?.thumbnails?.Tablet?.gatsbyImageData
    } else {
      return img?.gatsbyImageData
    }
  }

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    speed: 600,
  }

  return (
    <div className="blog-image-carousel overflow-hidden">
      <Slider {...settings}>
        {data.map((item, index) => (
          <div className="relative image-card" key={index}>
            <GatsbyImage
              image={imgUrl(item?.image)}
              className="slider-image"
              alt={item?.image?.alt || 'eminent blog carousel'}
            />
          </div>
        ))}
      </Slider>
    </div>
  )
}

export const fragments = graphql`
  fragment SliceBlogImageCarousel on PrismicBlogPostDataBodyImageCarousel {
    ... on PrismicBlogPostDataBodyImageCarousel {
      id
      slice_type
      items {
        image {
          thumbnails {
            Mobile {
              gatsbyImageData(layout: FULL_WIDTH)
            }
            Tablet {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          alt
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
`